<template>
  <v-container fluid class="backgroundW " id="appBar">
    <v-row justify="space-around" align="center">

      <v-col cols="auto" class="py-0 mt-3">
        <!-- <router-link to="/"> -->
        <AnimatedLogo height="40px" class="mt-n5" />
        <!-- </router-link> -->
      </v-col>
      <v-col class="d-flex justify-center appBar">

        <v-col cols="auto" class="pa-0" v-for="(screen, index) in $store.state.screens" :key="index">
          <v-btn depressed plain text :exact-path="false" :to="screen.url">
            {{ screen.sub_title }}

            <!-- customers counts -->
            <span v-if="screen.sub_title == $i18n.t('customers')">
              ({{ $store.state.userData.total_clients }})
            </span>
            <!-- calls and follow up counts -->
            <span v-if="screen.sub_title == $i18n.t('calls and follow up')">
              ({{ $store.state.userData.total_followup }})
            </span>
          </v-btn>
        </v-col>

      </v-col>


      <v-col cols="3" md="auto" sm="auto" class="mt-n3">
        <UserMenu></UserMenu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnimatedLogo from "./AnimatedLogo.vue";
import UserMenu from "./UserMenu.vue";

export default {
  name: "Appbar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".directive-fullscreen-wrapper",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  components: {
    UserMenu,
    AnimatedLogo
  },
  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>

<style lang="scss" scoped>
#appBar {
  position: fixed;
  height: 70px;
  z-index: 55;
  width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  display: flex;
  background-color: #F6F6FB !important;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04); */
}

.v-application .backgroundW {
  background-color: #ECECF5 !important;
  border-color: none !important;
}

.backgroundW a {
  font-weight: 600 !important;
  color: #8E8C94;
}

.v-application .py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /* margin-top: 15px !important; */
}

.v-btn {
  background-color: #ECECF5;
  border: none;
  box-shadow: none;
  width: 12rem;
  top: -2px;

}


.v-btn--active.v-btn.v-btn--router.v-btn--text.theme--light.v-size--default {
  background-color: #FFFFFF !important;
  color: #FFFFFF !important;
  border-top: 10px solid #4D77FF !important;
  border-radius: 6px 6px 0px 0px !important;
  padding: 25px 0px !important;
  font-weight: 600 !important;
  /* width: 10rem; */
}

.v-btn.v-btn--plain.v-btn--router.v-btn--text.theme--light.v-size--default {
  background-color: transparent;
}
</style>