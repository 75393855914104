<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn v-bind="attrs" v-on="on" text  class="overflow-hidden" > -->

        <div v-bind="attrs" v-on="on" class="d-flex align-center choose_menu">
          <!-- <v-avatar size="35px"> -->
          <img src="@/assets/img/svg/choose_menu.svg" alt="c.r.m" />
          <!-- </v-avatar> -->
          <!-- <div class="text-start">
            <h5 class="text-capitalize d-none d-lg-block mx-2">{{ $t('Options') }} <v-icon small
                class="mx-1">mdi-chevron-down</v-icon>
                src="/img/logo_eng.6682bcb7.svg"
            </h5>
          </div> -->
        </div>

        <!-- </v-btn> -->
      </template>

      <v-card width="300" class="py-0 mt-5 rounded-lg">
        <v-list-item-content class="justify-center">
          <div>
            <div class="userList d-flex justify-space-between px-4  align-center text-center" @click="userDialog = true">
              <v-avatar color="transparent" class="rounded-xl me-2" size="30">
                <img v-if="$store.state.userData.image != null"
                  :src="$store.state.endpointURL + $store.state.userData.image" alt />
                <img v-else src="@/assets/img/svg/user_menu_icon.svg" alt />
              </v-avatar>
              <div style="width: 85%;" class="text-start">
                <h5 class="mx-2 my-1">{{ $store.state.userData.name }}</h5>
              </div>
              <img src="@/assets/img/svg/user_menu_icon_edit.svg" alt />
            </div>

            <div class="userList d-flex justify-space-between px-4  align-center text-center" v-on:click="changeLanguage">
              <v-avatar color="transparent" class="rounded-0" size="25">
                <img src="@/assets/img/svg/language_use_icon.svg" alt="c.r.m" />
              </v-avatar>
              <div style="width: 85%;" class="text-start ">
                <h5 class="mx-2 my-1 lang">{{ $t("change language") | capitalize }}</h5>
              </div>
            </div>

            <div class="userList d-flex justify-space-between px-4 align-center text-center" @click="$api.LOGOUT(true)">
              <v-avatar color="transparent" class="rounded-0" size="25">

                <img src="@/assets/img/svg/logout.svg" alt="c.r.m" v-if="$vuetify.rtl" />
                <img src="@/assets/img/svg/logout_eng.svg" alt="c.r.m" v-else />
              </v-avatar>
              <div style="width: 85%;" class="text-start">
                <h5 class="mx-2 my-1 logout">{{ $t("logout") | capitalize }}</h5>
              </div>
            </div>
          </div>

          <UserDialogEdit :openDialog="userDialog" :closeDialog="() => userDialog = false"
            :rowData="$store.state.userData" :userDialog="userDialog" />

        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import UserDialogEdit from '../modals/UserDialogEdit.vue';

export default {
  name: "UserMenu",

  data: () => ({
    user: {},
    login_date: null,
    changePassDialog: false,
    userDialog: false,
  }),
  mounted() {
    this.login_date = localStorage.getItem("login_date");
  },
  computed: {},

  methods: {
    close(status) {
      this.changePassDialog = status;
    },
    // change Language
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
  components: { UserDialogEdit }
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px;
}

#appBarMenu .v-menu__content {
  top: 64px !important;
  box-shadow: none !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}

.userList {
  cursor: pointer;
  padding: 8px;
}

.userList:hover {
  background: #e6ecff73;
}

.choose_menu {
  padding: 10px;
  gap: 10px;
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 13px;
}

.lang {
  font-weight: 700;
  font-size: 14px;
  color: #0F0D15;
}

.logout {
  font-weight: 700;
  font-size: 14px;
  color: #F53E3E;
}
</style>
