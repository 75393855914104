<template>
  <v-dialog v-model="openDialog" :overlay-opacity="opacity" persistent max-width="500px" :retain-focus="false">
    <v-card relative class="backgroundW">

      <h4 class="pa-5">{{ $t(titleOne) }}
        <span>{{ titleTwo }}</span>
      </h4>

      <v-col cols="12" class="py-4">
        <v-row justify="end">

          <v-col cols="auto">
            <v-btn @click="confirmButton" :loading="saveDataLoading" depressed text
              class="rounded-lg font-weight-bold error--text red12" style="width: 100%; text-align: start">
              {{ $t("delete") | capitalize }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn @click="closeDialog" :loading="saveDataLoading" color="gray1" depressed outlined
              style="width: 100%; text-align: start">
              {{ $t("keep data") | capitalize }}
            </v-btn>
          </v-col>

        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>

<script>

export default {
  name: "LcalCustomModal",
  props: {
    openDialog: { default: false },
    saveDataLoading: { default: false },
    closeDialog: { type: Function },
    confirmButton: { type: Function },
    titleOne: { default: '' },
    titleTwo: { default: '' },
    opacity: { default: '' },


  },
  computed: {},
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.confirmButton();
      this.closeDialog();

    }
  },
  mounted() { }
};
</script>
