<template>
    <v-dialog v-model="openDialog" persistent max-width="480">
        <v-card relative class=" pa-6">
            <v-form ref="form" v-model="valid">

                <v-row align="center" class="mt-2 rounded-lg">
                    <GenericInput type="date" :value="form.from" @input="form.from = $event" label="from date"
                        :required="false" :isLoading="false" :clearable="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="date" :value="form.to" @input="form.to = $event" label="to date"
                        :minDate='form.from' :required="false" :isLoading="false" :clearable="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.seller_id" @input="form.seller_id = $event"
                        label="sales representative" :lookups="sellers" selected_label="name" selected_prop="id"
                        :paddingY="'py-3'" :required="false" :clearable="false" :isLoading="loading" :cols="[12, 12, 12]">
                        <template v-slot:append>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </GenericInput>

                    <GenericInput type="select" :value="form.call_response_type_id"
                        @input="form.call_response_type_id = $event" label="Possibility of response" :lookups="answerArr"
                        selected_label="name" selected_prop="id" :required="false" :clearable="false" :isLoading="loading"
                        :cols="[12, 12, 12]">
                    </GenericInput>
                </v-row>

                <v-card-actions class="d-flex justify-space-between align-center mt-2 ">
                    <v-btn color="red12" depressed :min-width="132" height="36" @click="close"
                        class="font-weight-bold rounded-lg  error--text" :loading="false">
                        {{ $t('exit') }}
                    </v-btn>

                    <v-btn outlined depressed :min-width="118" height="36" @click="clearFilter"
                        class="font-weight-bold rounded-lg  black--text" :loading="false">
                        {{ $t('remove the filter') }}
                    </v-btn>

                    <v-btn class="font-weight-bold info--text" :min-width="118" height="36" elevation="0" color="blue12"
                        :disabled="!valid" @click="save" :loading="false">
                        {{ $t('filter') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "FilterDialog",
    props: {
        closeDialog: { type: Function },
        returnFilterData: { type: Function },
        openDialog: { default: Boolean },
    },
    data: () => ({
        valid: false,
        loading: false,
        sellers: [],
        answerArr: [],
        form: {
            from: null,
            to: null,
            seller_id: null,
            call_response_type_id: null,
            filter_status: false,
        }
    }),
    methods: {
        save() {
            if (this.$refs.form.validate()) {
                this.form.filter_status = true
                this.returnFilterData({ ...this.form })
                this.closeDialog()
            }
        },
        clearFilter() {
            this.form.filter_status = false
            this.$refs.form.reset();
            this.closeDialog()
            this.returnFilterData(this.form)
        },
        close() {
            // keep filter state
            if (this.form.filter_status) {
                this.closeDialog()
            } else {
                // clear filter
                this.$refs.form.reset();
                this.closeDialog()
            }
        },
    },
    mounted() {

    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                if (!this.answerArr.length && !this.sellers.length) {
                    this.loading = true
                    this.$api.GET_METHOD(`follow-up/filter`).then((response) => {
                        this.loading = false
                        if (response.check) {
                            console.log('filter response', response.data);
                            this.answerArr = [...response.data.call_response_types];
                            this.sellers = [...response.data.sellers];
                        }
                    })
                }

                this.form.from = this.$router.currentRoute.query.from || null
                this.form.to = this.$router.currentRoute.query.to || null
                this.form.seller_id = +this.$router.currentRoute.query.seller_id || null
                this.form.call_response_type_id = +this.$router.currentRoute.query.call_response_type_id || null
            }
        },
    }

}
</script>