<template>
  <v-app v-resize="onResize">
    <SplashLoader v-if="$store.state.splashScreen == true"></SplashLoader>
    <router-view></router-view>
    <!-- <SpeedTest v-if="$store.state.splashScreen == false" /> -->
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/colors.scss";
@import "./scss/core.scss";
@import "./scss/sweetAlert.scss";
</style>
<script>
import SplashLoader from "@/components/ui/SplashLoader.vue";
// import SpeedTest from "@/components/SpeedTest.vue";

export default {
  name: "App",
  components: {
    SplashLoader,
    //  SpeedTest
  },
  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    this.$store.dispatch("checkRoute");

    console.log(this.$global.CheckAction('01-002', 1), 'sssss');
    // if (this.$router.push('/')) {
    //   this.$router.push('/customers')
    // }

  },
  watch: {
    $route() {
      console.log('$route', this.$route);
      this.$store.dispatch("checkRoute");
    }
  },
  created() {
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      this.refreshApp()
    },
    swUpdateFound(event) {
      console.log('swUpdateFound', event);
      this.$router.push('/system_update')
    },
    swOffline(event) {
      console.log('swOffline', event);
      this.$store.state.networkConnection = false;
      this.$global.ShowAlert('info', this.$i18n.t('No Internet Connection'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onResize() {
      this.$store.state.Settings.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
};
</script>
