<template>
    <v-dialog v-model="openDialog" persistent max-width="365">
        <v-card relative class=" pa-6">
            <v-form ref="form" v-model="valid">
                <v-row align="center" class="mt-2 rounded-lg">
                    <GenericInput type="date" :value="form.date" @input="form.date = $event" label="date" :required="false"
                        :isLoading="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="time" :value="form.hour" @input="form.hour = $event" label="the time"
                        :required="false" :isLoading="false" :paddingY="'py-3'" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="number" :value="form.duration" @input="form.duration = $event"
                        label="Call duration (in minutes)" :disabled="validatePossibilityReply" icon="mdi-note-text-outline"
                        :required="validatePossibilityReply ? false : true" :isLoading="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.possibility_reply_id"
                        @input="form.possibility_reply_id = $event" label="Possibility of response" :lookups="answerArr"
                        selected_label="name" selected_prop="id" :required="true" :isLoading="answerArrLoading"
                        :paddingY="'py-3'" :cols="[12, 12, 12]">
                    </GenericInput>
                </v-row>

                <v-card-actions class="d-flex justify-space-between align-center mt-2 ">
                    <v-btn color="red12" depressed :width="132" height="36" @click="close"
                        class="font-weight-bold rounded-lg  error--text" :loading="loading">
                        {{ $t('back') }}
                    </v-btn>

                    <v-btn class="font-weight-bold info--text" :width="153" height="36" elevation="0" color="blue12"
                        :disabled="!valid" @click="save" :loading="loading">
                        {{ $t('Add contact') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "AddCallsDialog",
    props: {
        backValueMethod: { type: Function },
        closeDialog: { type: Function },
        openDialog: { default: Boolean },
        customer_id: { default: Number },
    },
    data: () => ({
        valid: false,
        loading: false,
        answerArrLoading: false,
        answerArr: [],
        form: {
            client_id: null,
            possibility_reply_id: null,
            date: null,
            hour: null,
            duration: null,
        }
    }),
    methods: {
        save() {
            this.$route.params.id ? this.form.client_id = this.$route.params.id : this.form.client_id = this.customer_id
            this.loading = true
            if (this.$refs.form.validate()) {
                this.$api.POST_METHOD(`call/store`, this.form).then((response) => {
                    this.loading = false
                    if (response.check) {
                        console.log('response', response);
                        this.backValueMethod(response.data)
                        this.close()
                    }
                })
            }
        },
        currentHour() {
            let date = new Date();
            let hour = date.getHours();
            let minutes = date.getMinutes();
            console.log(hour + ':' + minutes);
            return hour + ':' + minutes
        },
        close() {
            this.$refs.form.reset();
            this.form.duration = null
            this.closeDialog()
        },
    },
    mounted() {

    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.form.date = this.$global.GetCurrentDate()
                this.form.hour = this.currentHour()
                this.answerArrLoading = true
                this.$api.GET_METHOD(`call_response_types`).then((response) => {
                    this.answerArrLoading = false
                    if (response.check) {
                        this.answerArr = response.data;
                    }

                })
            }
        },
        'form.duration'() {
            this.form.duration = Math.abs(this.form.duration)
        },
        'form.possibility_reply_id'() {
            if (this.validatePossibilityReply) {
                this.form.duration = null;
            }
        }
    },
    computed: {
        validatePossibilityReply() {
            return this.form.possibility_reply_id === 4 || this.form.possibility_reply_id === 3 || this.form.possibility_reply_id === 2
        }
    }

}
</script>