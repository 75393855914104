<template>
    <div>
        <v-row class="mx-n1 mt-3 mb-3">
            <v-col cols="12" sm="12">
                <v-tabs>
                    <v-tab to="/reports/sales_agent_statement">{{ $t('representative account statement') | capitalize
                    }}</v-tab>
                    <v-tab to="/reports/customer_statement">{{ $t('customer account statement') | capitalize }}</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <router-view></router-view>
    </div>
</template>
  
  
<script>
export default {
    name: "Reports",

    data: () => ({


    }),
    components: {

    },

    computed: {

    },
    watch: {


    },
    methods: {

    },

}
</script>
<style lang="scss" scoped>
.v-tab {
    font-weight: 400;
    font-size: 14px;
    color: #8E8C94;
    width: 241px;
    font-weight: 500 !important;
    top: -3px;

}




/* .theme--light.v-tabs>.v-tabs-bar .v-tab {
    font-weight: 500 !important;
    width: 241px;
} */

.v-tab.v-tab--active {
    background: #E6ECFF;
    border-bottom: 5px solid #4D77FF;
    border-radius: 6px 6px 0px 0px;
    padding: 16px 55px;
    top: 0px;
    font-size: 14px;
    color: #0030CC !important;
}
</style>