<template>
    <section>
        <v-container fluid>
            <section class="mx-2">
                <v-form ref="form" v-model="valid">
                    <v-row class="pt-1  statement ">
                        <v-col cols="11" sm="11" lg="11" class="d-flex">
                            <GenericInput type="date" :value="form.from" @input="form.from = $event" label="from date"
                                :required="true" :isLoading="false" :clearable="true" :cols="[4, 4, 4]">
                            </GenericInput>

                            <GenericInput type="date" :value="form.to" @input="form.to = $event" label="to date"
                                :minDate='form.from' :required="true" :isLoading="false" :clearable="true"
                                :cols="[4, 4, 4]">
                            </GenericInput>

                            <GenericInput type="select" :lookups="sellersLookups" :value="form.seller_id"
                                @input="form.seller_id = $event" label="name of the representative" :required="true"
                                selected_label="name" selected_prop="id" :clearable="true" :isLoading="false"
                                :cols="[4, 4, 4]">
                                <template v-slot:append>
                                    <v-icon>mdi-magnify</v-icon>
                                </template>
                            </GenericInput>
                        </v-col>

                        <!-- search -->
                        <v-col @click="getData" cols="1" sm="1" lg="1" class="d-flex justify-center">
                            <v-btn depressed :width="90" :loading="isLoading" color="blue11"
                                class="primary--text  mt-1 search font-weight-bold" :disabled="!valid">
                                {{ $t('search') | capitalize }}
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-form>
            </section>
            <!-- ANIMATION loading -->
            <AnimatedLoading classes="white ma-auto loading_component_All" :hideText="false" :isLoading="isLoading" />

            <!-- details -->
            <section class="details pa-5 mx-n3" v-if="Object.keys(seller).at(1)" v-show="!isLoading">
                <v-row class="mt-1 statement">
                    <!-- header title -->
                    <v-col cols="12">
                        <h5>
                            <span class="mx-1 d-inline-block">
                                {{ $t('statement of account for delegate') | capitalize }}</span>
                            <span class="mx-1 d-inline-block">{{ seller.user.name }}</span>
                            <span class="mx-1 d-inline-block">{{ $t('during the day') | capitalize }}</span>:
                            <span class="mx-1 d-inline-block">{{ seller.user.report_duration }}</span>
                        </h5>
                    </v-col>

                    <!-- user details -->
                    <v-col cols="12" md="6" class="d-flex px-5">

                        <!-- avatar -->
                        <v-avatar v-if="!seller.user.image" color="transparent" class="" size="80">
                            <img src="@/assets/img/svg/user_menu_icon.svg" alt />
                        </v-avatar>
                        <v-avatar v-else color="transparent" class="" size="80">
                            <img :src="$store.state.endpointURL + seller.user.image" alt="img" />
                        </v-avatar>

                        <!-- creation date -->
                        <span class="mx-7 mt-2">
                            <h1 class="mb-4">{{ seller.user.name }}</h1>
                            <span>{{ $t('creation date') | capitalize }}</span> <span>{{ seller.user.created_at }}</span>
                        </span>
                    </v-col>

                    <!-- customers  period details-->
                    <v-col cols="12" md="6">
                        <div class="d-flex justify-space-between px-16 text-center customers_period_details ">

                            <!-- registered clients -->
                            <div class="pa-2 cursor_pointer" @click="handelReportsDialog('customer_without_calls')">
                                <h4 class="mb-3">{{ $t('customers registered during the period') }}</h4>
                                <h4>{{ seller.registered_clients }}</h4>
                            </div>

                            <!-- calls -->
                            <div class="pa-2 cursor_pointer" @click="handelReportsDialog('customer_with_calls')">
                                <h4 class="mb-3">{{ $t('calls during the period') }}</h4>
                                <h4>{{ seller.calls }}</h4>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <!-- START Country details -->
                <v-row class="mt-8 statement" v-for="(records, i) in seller.records" :key="i">
                    <!-- First counter details -->
                    <v-col cols="12" md="12">
                        <div class="country pa-5 d-flex justify-space-around text-center">
                            <span>
                                <span>{{ $t('country') }}</span>
                                <h5 class="mt-3">{{ records.country }}</h5>
                            </span>
                            <v-divider class="mx-2" vertical></v-divider>
                            <span>
                                <span>{{ $t('target') }}</span>
                                <h5 class="mt-3">{{ records.target }}</h5>
                            </span>
                            <v-divider class="mx-2" vertical></v-divider>
                            <span>
                                <span>{{ $t('perfid') }}</span>
                                <h5 class="mt-3">{{ records.achived }}</h5>
                            </span>
                            <v-divider class="mx-2" vertical></v-divider>
                            <span>
                                <span>{{ $t('rest') }}</span>
                                <h5 class="mt-3">{{ records.left }}</h5>
                            </span>
                            <v-divider class="mx-2" vertical></v-divider>
                            <span>
                                <span>{{ $t('commission rate') }}</span>
                                <h5 class="mt-3">{{ records.comission }}</h5>
                            </span>
                            <v-divider class="mx-2" vertical></v-divider>
                            <span>
                                <span>{{ $t('commission value') }}</span>
                                <h5 class="mt-3">{{ records.comission_value }}</h5>
                            </span>
                        </div>
                    </v-col>

                </v-row>
                <!-- END Country details -->

                <v-row class="mt-10 mb-9 statement">
                    <!-- header title -->
                    <v-col cols="12">
                        <h5>{{ $t('customer cases') }}</h5>
                    </v-col>

                    <!-- customers  period details-->
                    <v-col cols="12">
                        <div class="d-flex justify-space-between ">

                            <div class="customer_cases" v-for="cases in seller.cases" :key="cases.id">
                                <h4 class="mb-3">{{ $t(cases.name) }}</h4>
                                <h4>{{ cases.count }}</h4>
                            </div>

                        </div>
                    </v-col>
                </v-row>
            </section>

            <!-- search document no data img-->
            <div class="search-document" v-if="Object.keys(seller).length == 0 && !isLoading">
                <img src="@/assets/img/svg/search-document.svg" alt="search-document">
                <h5 class="description mt-3">{{ $t('search for the report') }}</h5>
            </div>

        </v-container>
        <CustomersReportsDialog :openDialog="customers_dialog" :closeDialog="() => customers_dialog = false" :data="form" />
    </section>
</template>
  
<script>
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import CustomersReportsDialog from './CustomersReportsDialog.vue';


export default {
    name: "SalesAgentStatement",

    data: () => ({
        pageData: {
            screen_code: "01-003",
            url: null,
            controlRoute: "/reports/sales_agent_statement",
            entityName: null,
            main: null,
            category: null,
            isLoading: false,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        comparisonPeriod: [],
        sellersLookups: [],
        seller: {},
        isLoading: false,
        valid: false,
        customers_dialog: false,
        tab: 0,
        form: {
            seller_id: null,
            from: null,
            to: null,
        }
    }),
    components: { AnimatedLoading, CustomersReportsDialog },
    computed: {},
    mounted() {
        this.getSellersData()
    },
    watch: {
        'form.seller_id'() {
            if (this.form.seller_id) {
                this.getData()
            }
        }
    },
    methods: {
        getSellersData() {
            this.isLoading = true
            this.$api.GET_METHOD(`sellers/all`).then((response) => {
                this.isLoading = false;
                if (response.check) {
                    console.log("response", response);
                    this.sellersLookups = response.data.sellers

                }
            })
        },

        getData() {
            if (this.$refs.form.validate()) {
                this.isLoading = true
                this.pageData.isLoading = false;
                this.$api
                    .GET_METHOD(`reports/seller?seller_id=${this.form.seller_id}&from=${this.form.from}&to=${this.form.to}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        this.isLoading = false

                        if (response.check) {
                            this.seller = response.data;
                            this.pageData.entityName = this.$i18n.t("users")
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                        }
                    });
            }
        },
        handelReportsDialog(name) {
            this.form.action_type = name
            this.customers_dialog = true
        },
        edit() {
            // this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
            this.$router.push(`/customers/customers-control`);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.loading_component_All {
    padding-top: 2rem !important;
}

.statement {
    background: #F9F9F9;
    border-radius: 6px;
    padding: 0 5px;
}

.search {
    background: #ECF0FF;
    border-radius: 8px;

}

.customers_period_details {
    background: #E6ECFF;
    border-radius: 6px;
    color: #0030CC;
    padding: 16px;


}

.country {
    background: #FFF0E8;
    border-radius: 6px;
}

.country span {
    color: #E65300;
}

.v-divider {
    border: 1px solid #FFCFB4;
}

.customer_cases {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    margin: 0 15px;
    width: 180px;
}

.customer_cases:nth-child(1) {
    color: #FF7F37;
}

.customer_cases:nth-child(2) {
    color: #1A50FF;
}

.customer_cases:nth-child(3) {
    color: #F65354;
}

.customer_cases:nth-child(4) {
    color: #35EBBD
}

.customer_cases:nth-child(5) {
    color: #CCB243
}

.customer_cases:nth-child(6) {
    color: #18BA92
}

.customer_cases:nth-child(7) {
    color: #C32021
}


.search-document {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.search-document .description {
    font-weight: 700;
    font-size: 21.1261px;

    color: #8E8C94;
}
</style>