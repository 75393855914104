<template>
    <section class="mt-3">
        <v-container fluid>
            <!-- Header -->
            <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" :showDeleted="false"
                :createBtn="false" createBtnText="" actionBtnText="add" :actionBtn="true"
                :actionBtnClick="() => userDialog = true" :actionBtnValid="true" actionBtnColor="info" />
            <!-- End Of Header -->

            <!-- tabs -->
            <v-row class="mt-5">
                <v-col cols="12" sm="12">
                    <v-tabs v-model="tab">
                        <v-tab>{{ $t('available users') | capitalize }}</v-tab>
                        <v-tab>{{ $t('unavailable users') | capitalize }}</v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-row v-if="tab == 0">
                <v-col cols="12" sm="12">
                    <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                        :deleteValueMethod="toggleActive" :restoreValueMethod="restoreMethod">
                        <template v-slot:actions="{ row }">
                            <span v-if="row.user_position_id !== 1">
                                <v-tooltip bottom color="error">
                                    <template v-slot:activator="{ on, attrs }">
                                        <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                                            @click="toggleActive(row.id)" src="@/assets/img/svg_icons/delete_icon.svg"
                                            alt="crm" />
                                    </template>
                                    <span>{{ $t('delete') }}</span>
                                </v-tooltip>
                            </span>
                            <span v-if="row.user_position_id === 1" class="px-1">
                                <v-tooltip bottom color="green6">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="green6" v-bind="attrs" v-on="on">mdi-shield-crown-outline</v-icon>
                                    </template>
                                    <span>{{ $t('admin') }}</span>
                                </v-tooltip>
                            </span>
                        </template>
                    </DynamicTable>
                </v-col>
            </v-row>

            <!-- unavailable users -->
            <v-row v-if="tab == 1">
                <v-col cols="12" sm="12">
                    <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                        :deleteValueMethod="toggleActive" :restoreValueMethod="restoreMethod">

                        <!-- START slot -->
                        <template v-slot:td="{ row, header }">

                            <div v-if="header.key == 'toggle_slot'" style="cursor: default;" @click.stop>
                                <v-btn @click="toggleActive(row.id)" depressed :width="90" :loading="saveDataLoading"
                                    outlined class="mx-2 activate">
                                    {{ $t('activate') }}
                                </v-btn>
                                <v-btn @click="permanentDeletion(row)" v-if="row.user_position_id !== 1" depressed
                                    :width="90" outlined class="mx-2 deletion">
                                    {{ $t('permanent deletion') }}
                                </v-btn>
                            </div>

                            <!-- Edit -->
                            <div v-if="header.key == 'slot_edit'" class="d-flex" style="cursor: default;" @click.stop>

                            </div>
                        </template>

                        <!-- END slot -->
                    </DynamicTable>
                </v-col>
            </v-row>

            <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                <v-col cols="12" sm="12">
                    <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                        :isLoading="pageData.isLoading"></Pagination>
                </v-col>
            </v-row>
        </v-container>

        <UserDialogEdit :openDialog="userDialog" :closeDialog="() => (userDialog = false, rowData = {})" :rowData="rowData"
            :backValueMethod="getData" />

        <!-- Confirm modal -->
        <LocalCustomModal :confirmButton="confirm" :openDialog="confirm_dialog" :closeDialog="() => confirm_dialog = false"
            :saveDataLoading="deleteLoading" :titleOne="'are you sure to delete'" :opacity="0.3"
            :titleTwo="exitRowData.name" />
    </section>
</template>
  
<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import LocalCustomModal from "@/components/modals/LocalCustomModal.vue";
import UserDialogEdit from "@/components/modals/UserDialogEdit.vue";
export default {
    name: "UsersEntity",

    data: () => ({
        pageData: {
            screen_code: "01-004",
            url: null,
            controlRoute: "/users",
            entityName: 'users',
            main: null,
            category: null,
            isLoading: false,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: new URLSearchParams(),
        },
        row_id: "",
        deleteLoading: false,
        confirm_dialog: false,
        rowData: {},
        exitRowData: {},
        tab: null,
        openDialog: false,
        userDialog: false,
        saveDataLoading: false
    }),
    components: {
        EntityHeader,
        Pagination,
        DynamicTable,
        LocalCustomModal,
        UserDialogEdit
    },
    computed: {},
    mounted() {
        this.queryParams();
        this.pageMainData();
        // this.getData();
    },
    watch: {
        $route() {
            this.pageData.queryParam = new URLSearchParams();
            this.queryParams();
            this.pageMainData();
            this.getData();
        },

        tab() {
            switch (this.tab) {
                //  
                case 0:
                    this.pageData.tableHeader = [
                        { text: "serial", key: "id", type: 'text', classes: "" },
                        { text: "added date", key: "created_at", type: 'date', classes: "" },
                        { text: "user name", key: "name", type: 'text', classes: "" },
                        { text: "position", key: "position_name", type: 'text', classes: "" },
                        { text: "actions", key: "id", type: 'actions', classes: "" },
                    ];
                    this.getData()
                    break;

                // in the plan TAB
                case 1:

                    this.pageData.tableHeader = [
                        { text: "serial", key: "id", type: 'text', classes: "" },
                        { text: "added date", key: "created_at", type: 'date', classes: "" },
                        { text: "user name", key: "name", type: 'text', classes: "" },
                        { text: "position", key: "position_name", type: 'text', classes: "" },
                        { text: "", key: "toggle_slot", type: 'slot', classes: "" },
                        // { text: "actions", key: "id", type: 'actions', classes: "" },
                    ];

                    this.getData()
                    break;

                default:
                    break
            }

        },

    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
        },
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).main_title;
            this.pageData.category = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).cat_title;
            // this.pageData.entityName = this.$global.FilterPermissions(
            //     this.pageData.screen_code
            // ).sub_title;
            this.pageData.entityName = this.$i18n.t("users")
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            // this.pageData.tableHeader = [

            // ];
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: false,
                // delete: false,
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };
        },
        getSearch(word) {
            this.pageData.queryParam.set("word", word || '')
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
        },

        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.tab === 1 ? this.pageData.queryParam.set("active", 0) : this.pageData.queryParam.set("active", 1)
                this.$api.GET_METHOD(`users?${this.pageData.queryParam.toString()}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.pageData.entityName = this.$i18n.t("users")
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                            this.pageData.rows = response.data.data;
                            this.pageData.page = response.data.current_page;
                            this.pageData.pagination = {
                                page: response.data.current_page,
                                totalPages: response.data.last_page,
                                per_page: response.data.per_page,
                                totalRows: response.data.total,
                            };

                        }
                    });
            } else {
                this.$router.push("/");
            }
        },

        confirm() {
            this.deleteLoading = true
            this.$api.DELETE_METHOD(`delete_user`, this.exitRowData.id).
                then((response) => {
                    this.deleteLoading = false
                    if (response.check) {
                        this.confirm_dialog = false
                        this.getData();
                    }
                });
        },

        changePage(page, limit) {
            this.pageData.queryParam.set("page", page || 1);
            this.pageData.queryParam.set("rows", limit || 15);
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString())
        },


        toggleActive(id) {
            this.saveDataLoading = true
            this.$api.POST_METHOD(`user/toggleActive/${id}`, null).then((response) => {
                this.saveDataLoading = false
                if (response.check) {
                    this.getData();
                }
            });
        },

        restoreMethod(row) {
            this.$api.POST_METHOD(`users/${row.id}`, null).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        setClickRow() {
            // this.$router.push(`/main/purchases_vendor/${row.id}`)
        },
        edit(row) {
            console.log("edit", row);
            // this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
            this.rowData = row
            this.userDialog = true
        },

        permanentDeletion(row) {
            this.exitRowData = row
            this.confirm_dialog = true
            console.log(row);
        }
    },
};
</script>
  
<style  lang="scss" scoped>
.v-tab {
    font-weight: 400;
    font-size: 14px;
    color: #8E8C94;
    width: 280px;
    font-weight: 500 !important;
    top: -3px;

}

.v-tab.v-tab--active {
    background: #E6ECFF;
    border-bottom: 5px solid #4D77FF;
    border-radius: 6px 6px 0px 0px;
    padding: 16px 55px;
    top: 0px;
    font-size: 14px;
    color: #0030CC !important;

}

.activate {
    background: #ECF0FF;
    border-radius: 8px;
    color: #1E80F0;
    border: none
}

.deletion {
    background: #FEEAEA;
    color: #C32021;
    border-radius: 8px;
    border: none
}
</style>