<template>
  <v-container fluid class="  d-flex pa-0 align-center " style="height: 100vh">

    <div class="bg-img"></div>
    <span class="img-1" alt=""></span>
    <span class="img-2" alt=""></span>
    <span class="img-3" alt=""></span>
    <span class="img-4" alt=""></span>
    <span class="img-5" alt=""></span>
    <!-- <img class="img-2" src="../../assets/img/svg/Ellipse 2.svg" alt="">
    <img class="img-3" src="../../assets/img/svg/Line 1.svg" alt="">
    <img class="img-4" src="../../assets/img/png/circiles.svg">
    <img class="img-5" src="../../assets/img/png/Group-1.svg" alt=""> -->




    <v-container class="pa-0">
      <v-row justify="center" style=" z-index: 2">

        <v-col cols="11" sm="9" md="7" lg="5" xl="4">
          <v-card class="shadow-lg pa-5 rounded-lg">
            <v-col cols="12" class="d-flex justify-space-between align-space-between">

              <v-col cols="auto" class="">
                <!-- <img class="logo" src="@/assets/img/svg/logo_en.svg" alt="" /> -->
                <img v-if="$vuetify.rtl == true" src="@/assets/img/svg/logo.svg" alt="task_manager" width="80"
                  height="80" />
                <img v-else src="@/assets/img/svg/logo_eng.svg" alt="task_manager" width="80" height="80" />
              </v-col>

              <v-col cols="auto" class="mt-5">
                <img class="T_M_S" src="@/assets/img/svg/C.R.M.svg" alt="T.M.S" />
              </v-col>
            </v-col>
            <v-row>
              <v-col cols="12">

                <v-form ref="form" class="px-5" v-model="valid" lazy-validation>
                  <div class="d-block d-md-none">
                    <img class="d-block ma-auto pb-5" src="@/assets/img/svg/logo.svg" height="110" alt="">
                  </div>
                  <!-- <h1>{{ $t("Login Page") | capitalize }}</h1>;'.' -->
                  <!-- company number -->
                  <!-- <GenericInput type="text" name="company_number" paddingY="py-3" icon="mdi-shield-check"
                    :keydownEnter="login"  :value="form.company_number"
                    @input="form.company_number = $event" label="company number" :required="true" :isLoading="false"
                    :cols="[12, 12, 12]"></GenericInput> -->
                  <!-- username -->
                  <GenericInput type="text" name="username" paddingY="py-3" icon="mdi-account" :keydownEnter="login"
                    :value="form.username" @input="form.username = $event" label="username" :required="true"
                    :isLoading="false" :cols="[12, 12, 12]"></GenericInput>
                  <!-- password -->
                  <GenericInput type="password" name="password" paddingY="py-3" :keydownEnter="login"
                    :value="form.password" @input="form.password = $event" label="password" :required="true"
                    :isLoading="false" :cols="[12, 12, 12]">
                  </GenericInput>
                  <v-row class="mb-7 px-5">
                    <!-- <v-col cols="12" class="d-flex justify-end py-3">
                      <v-btn depressed :height="40" width="150" text plain to="/forget-password" >
                        {{ $t('forget password') }} {{ $t('?') }}
                      </v-btn>
                    </v-col> -->
                    <v-col class="py-1 mt-6">
                      <v-btn color="primary" depressed :height="40" width="100%" :disabled="!(valid)" :loading="loading"
                        @click="login">{{ $t('Login') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-divider class="mx-4"></v-divider> -->
                  <div class="lang text-center mt-10 ">
                    <v-btn outlined color="gray6" width="234" @click="changeLanguage" style="border: none;">
                      <!-- <img class="mx-2" v-if="$vuetify.rtl" src="@/assets/img/svg/USA.svg" alt=""> 
              <img class="mx-2" v-else src="@/assets/img/svg/KSA.svg" alt=""> -->
                      <div>
                        {{ $vuetify.rtl ? $t("english") : $t("arabic") }}
                      </div>
                    </v-btn>

                  </div>
                </v-form>


              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
  name: "LoginPage",
  computed: {},
  watch: {
    'form.company_number'() {
      // this.$store.commit('UPDATE_CLIENT', this.form.company_number || null)
      // this.$store.commit('UPDATE_CLIENT', 'tm_new')

    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    this.$store.commit('UPDATE_CLIENT', 'crm')

  },
  components: {
    GenericInput
  },
  data: () => ({
    valid: false,
    loading: false,
    form: {
      // email: null,
      username: null,
      password: null,
      // company_number: 'tm_new',
    }
  }),

  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true && this.$store.state.clientID) {
        this.$api.LOGIN_AUTH(this.form).then((response) => {
          this.loading = false;
          if (response.check) {
            this.$router.push('/customers');
            localStorage.setItem("clientID", this.$store.state.clientID)
          }
        })
      }
      else {
        this.loading = false;
      }
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },

};
</script>

<style scoped lang="scss">
.shadow-lg {
  box-shadow: 0px 0px 20px rgba(132, 132, 132, 0.2) !important;
}


.img-1 {
  bottom: 0;
  position: absolute;
  top: 27rem;
  right: 80rem;
  background-color: #809AF2;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  z-index: 1;
}

.img-2 {
  position: absolute;
  top: 7rem;
  right: 60rem;
  background-color: #809AF2;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  z-index: 1;
}

.img-3 {
  position: absolute;
  position: absolute;
  top: 30rem;
  right: 20rem;
  background-color: #809AF2;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  z-index: 1;
}

.img-4 {
  position: absolute;
  top: 90px;
  right: 10rem;
  background-color: #3363FF;
  border-radius: 50%;
  width: 230px;
  height: 230px;
  z-index: 1;
}

.img-5 {
  bottom: 0;
  position: absolute;
  top: 5rem;
  right: 99rem;
  background-color: rgba(128, 154, 242, 1);
  border-radius: 50%;
  width: 230px;
  height: 230px;
  z-index: 1;

}

.bg-img {
  background-image: url('../../assets/img/svg/bg-img.svg');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-attachment: inherit;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.63);
  backdrop-filter: blur(50px);

}

/* 
.bg-img-2 {
  background-image: url('../../assets/img/svg/bg-img.svg');
  background-size: cover;
  width: 100vw;
  height: 94vh;
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -37px;
  background-color: red;

} */

.theme--light.v-sheet {
  background-color: #FFF !important;
  box-shadow: 0px 0px 20px rgb(132 132 132 / 20%) !important;
  border-radius: 16px !important;
  z-index: 4;
}
</style>