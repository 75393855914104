<template>
    <section>
        <v-container fluid>
            <!-- Header -->
            <EntityHeader class="py-3" :showPrint="true" :pageData="pageData" :getData="getData" :getSearch="getSearch"
                createBtnText="add" :showDeleted="$store.state.userData.user_position_id === 1" />
            <!-- End Of Header -->

            <v-row>
                <v-col cols="12" sm="12">
                    <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                        :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod">
                        <template v-slot:actions="{ row }">
                            <span>
                                <v-menu offset-y open-on-hover transition="slide-x-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="gray"
                                            class="cursor_pointer mx-1">mdi-account-circle-outline</v-icon>
                                    </template>
                                    <v-list min-width="220">
                                        <v-list-item class="flex-wrap text-center">
                                            <v-list-item-title class="text-caption text-start">
                                                <div>{{ $t("added by") }}: {{ row.created_by }}</div>
                                                <div class="mt-3">
                                                    {{ $t("the responsible representative") }}:
                                                    {{ row.seller.name }}
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </span>

                            <span v-if="row.seller.id === $store.state.userData.id
                                || $store.state.userData.user_position_id === 1">
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="edit(row)" v-bind="attrs" v-on="on" width="30" height="30" icon fab>
                                            <img class="mx-2 cursor_pointer" style="height: 16px;"
                                                src="@/assets/img/svg_icons/pen.svg" alt="crm" />
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('edit') }}</span>
                                </v-tooltip>
                            </span>

                        </template>
                    </DynamicTable>
                </v-col>
            </v-row>

            <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                <v-col cols="12" sm="12">
                    <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                        :isLoading="pageData.isLoading"></Pagination>
                </v-col>
            </v-row>
        </v-container>



    </section>
</template>
  
<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
export default {
    name: "CustomersEntity",

    data: () => ({
        pageData: {
            screen_code: "01-001",
            url: null,
            controlRoute: "customers/customers-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        row_id: "",
        openDialog: false,
    }),
    components: {
        EntityHeader,
        Pagination,
        DynamicTable,
    },
    computed: {},
    mounted() {
        this.queryParams();
        this.pageMainData();
        this.getData();
    },
    watch: {
        $route() {
            this.queryParams();
            this.pageMainData();
            this.getData();
        },
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
        },
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).main_title;
            this.pageData.category = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).cat_title;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            this.pageData.tableHeader = [
                { text: "serial", key: "id", type: 'text', classes: "" },
                { text: "customer name", key: "name", type: 'text', classes: "" },
                { text: "cell phone", key: "phone", type: 'text', classes: "" },
                { text: "country", key: "country_name", type: 'text', classes: "" },
                { text: "the city", key: "city_name", type: 'text', classes: "" },
                { text: "region", key: "area_name", type: 'text', classes: "" },
                { text: "actions", key: "id", type: 'actions', classes: "" },
            ];
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: false,
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                // view: this.$global.CheckAction(this.pageData.screen_code, 1),
                sortBy: "id",
            };
        },
        getSearch(word) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.$api.GET_METHOD(`clients${this.pageData.queryParam}`).then((response) => {
                    this.pageData.isLoading = false;
                    if (response.check) {
                        console.log('clients response', response);
                        this.$store.state.userData.total_clients = response.data.total
                        this.$store.state.userData.total_followup = response.data.total_followup
                        this.pageData.entityName =
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                        response.data.clients.data.forEach(row => {
                            row.country_name = row.country ? row.country.name : '-';
                            row.city_name = row.city ? row.city.name : '-';
                            row.area_name = row.area ? row.area.name : '-';
                        });
                        this.pageData.rows = response.data.clients.data;
                        this.pageData.page = response.data.clients.current_page;
                        this.pageData.pagination = {
                            page: response.data.clients.current_page,
                            totalPages: response.data.clients.last_page,
                            per_page: response.data.clients.per_page,
                            totalRows: response.data.clients.total,
                        };

                    }
                });
            } else {
                this.$router.push("/");
            }
        },
        changePage(page, limit) {
            this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
            this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
        },
        deleteMethod(id) {
            console.log(id);
            this.$api.POST_METHOD(`client/toggleActive/${id}`).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        restoreMethod(row) {
            this.$api.POST_METHOD(`client/toggleActive/${row.id}`, null).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        setClickRow() {
            // this.$router.push(`/main/purchases_vendor/${row.id}`)
        },
        edit(row) {
            console.log("edit", row);
            this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
        },
    },
};
</script>
  
<style lang="scss" scoped></style>