<template>
  <v-dialog v-model="dialog" max-width="340" persistent>
    <v-card relative class="pa-5">
      <v-row align="center">
        <v-col cols="12">
          <span class="font-weight-bold red--text text-h6">{{ $t("exit") }}</span>
        </v-col>
        <v-col cols="12">
          <p class="subtitle-1 font-weight-medium">
            {{ $t("Are you sure that the deletion process will delete all data") }}
          </p>
        </v-col>
        <v-col cols="6">
          <v-btn depressed text @click="returnData" width="100%" height="36"
            class="rounded-lg font-weight-bold error--text red12">
            {{ $t('delete') }}
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn color="gray1" depressed outlined width="100%" height="36" @click="closeDialog"
            class="rounded-lg font-weight-bold  ">
            {{ $t('keep data') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
export default {
  name: "DeleteConfirmation",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    item: { default: "" },

  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      counter: 5
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.backValueMethod(false);
      this.counter = 5
    },
    returnData() {
      this.dialog = false
      this.backValueMethod(true);
      // this.counter = 5
    },
    countDown() {
      setTimeout(() => {
        this.counter = this.counter - 1
        if (this.counter !== 0) {
          this.countDown()
        }
      }, 1000);
    },
  },
  mounted() {
    this.countDown();
  }
};
</script>
