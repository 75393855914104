<template>
   <v-dialog v-model="openDialog" persistent max-width="800">
      <AnimatedLoading classes="white ma-auto loading_component" :hideText="false" :isLoading="isLoading"
         v-if="isLoading" />

      <v-card relative class="pa-5" v-if="!isLoading">
         <v-form ref="form" v-model="valid">
            <div>
               <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                  color="red5 lighten-1" text @click="closeDialog">
                  <v-icon color="red5 lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-card-title class="text-h6 text-center font-weight-bold">
                  <h5 class="d-flex font-weight-bold text-h6 justify-end">
                     {{ $t(dialog_title) | capitalize }}
                  </h5>
               </v-card-title>
               <span>
                  <v-row v-for="(line, i) in addLine" :key="i" align="center" class="mt-1">
                     <v-col :cols="11" class="d-flex pa-1">
                        <GenericInput type="select" :lookups="filterCountry" :value="addLine[i].country_id"
                           @input="addLine[i].country_id = $event" label="country" :required="true" selected_label="name"
                           selected_prop="id" disableItem="disable" :isLoading="false" :clearable="true"
                           :cols="[4, 4, 4]" />

                        <!-- target -->
                        <GenericInput type="float" :value="addLine[i].target" @input="addLine[i].target = $event"
                           label="traget" :required="true" :isLoading="false" :cols="[4, 4, 4]" />

                        <!-- comission -->
                        <GenericInput type="number" :value="addLine[i].comission" @input="addLine[i].comission = $event"
                           label="commission rate" :multi="false" :required="true" selected_label="name" selected_prop="id"
                           :isLoading="false" :cols="[4, 4, 4]" />
                     </v-col>
                     <!-- country -->

                     <v-col cols="1" class="pa-1"> <img class="cursor_pointer " @click="removeLine(addLine[i], i)"
                           src="@/assets/img/svg/delete_icon.svg" alt="delete" style="width:16px;height:16px" />
                     </v-col>

                  </v-row>
               </span>
               <v-row class="mt-1" align="center" justify="center">
                  <v-col cols="12" class="pb-1">
                     <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12">
                     <v-btn color="green5" depressed block :disabled="false" @click="addLines"
                        class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">
                        {{ $t('add line') }}
                     </v-btn>
                  </v-col>

                  <v-col cols="2">

                     <v-btn class="error--text red11 font-weight-bold " @click="closeDialog" block depressed>
                        {{ $t("exit") }}
                     </v-btn>
                  </v-col>

                  <v-col cols="10">
                     <v-btn color="blue12" depressed block @click="save" :disabled="addLine.length === 0 || !valid"
                        class="rounded-lg font-weight-bold secondary--text" :loading="saveDataLoading">
                        {{ $t('save') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </div>
            <v-row justify="center" v-if="getDataLoading">
               <v-col cols="auto" class="my-10">
                  <AnimatedLoading :height="143" :hideText="false" :isLoading="getDataLoading" />
               </v-col>
            </v-row>
         </v-form>
      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';

export default {
   name: "UserTargetDialog",
   data() {
      return {

         valid: false,
         getDataLoading: false,
         isLoading: false,
         loading: false,
         saveDataLoading: false,
         id: null,
         country: [],
         addLine: [
            {
               country_id: null,
               target: null,
               comission: null,
            }
         ],

      };
   },


   methods: {
      close() {
         this.addLine = [
            {
               id: null,
               country_id: null,
               target: null,
               comission: null,
            }
         ]
         this.id = 0
         this.closeDialog()
         this.closeUserDialog()

      },

      addLines() {
         if (!this.position_id) {
            // in add new user
            this.addLine.push({
               country_id: null,
               target: null,
               comission: null,
            })
         } else {
            // in update on current user
            this.addLine.push({
               id: 0,
               country_id: null,
               target: null,
               comission: null,
            })
         }

      },

      removeLine(row, i) {
         console.log('row', row);
         this.country.map((country) => country.id === row.country_id ? country.disable = false : country)
         this.addLine.splice(i, 1)
      },

      getData() {
         this.isLoading = true
         this.$api.GET_METHOD(`countries`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               console.log("response", response);
               response.data.forEach((element) => {
                  element.disable = false
               });
               this.country = [...response.data]
               console.log("country", this.country);
            }
         })
      },


      save() {

         // Edit  request
         this.isLoading = true
         if (Object.keys(this.rowData).length !== 0) {
            this.UserControlForm.id = this.rowData.id

            this.UserControlForm.details = this.addLine

            if (this.UserControlForm.image === this.rowData.image) {
               delete this.UserControlForm.image;
            }
            this.addLine.forEach(keys => {
               if (keys) {
                  delete keys.created_at;
                  delete keys.updated_at;
                  delete keys.user_id;
               }
            });
            this.$api.POST_METHOD(`user/update`, this.UserControlForm).then((response) => {
               this.isLoading = false
               if (response.check) {

                  if (this.userDialog) {
                     this.$router.push('/customers');
                  } else {

                     this.backValueMethod(false)
                  }


               }
               this.close();
            })
         } else {
            // Add  request
            this.UserControlForm.details = this.addLine

            this.isLoading = true
            this.$api.POST_METHOD(`user/store`, this.UserControlForm).then((response) => {
               this.isLoading = false

               if (response.check) {
                  this.backValueMethod(false)
                  this.close();
               }
            })
         }
      },

   },

   watch: {
      openDialog() {
         if (this.openDialog == true) {
            this.getData()
            if (Object.keys(this.rowData).length !== 0) {
               if (this.userInfo.length > 0) {
                  this.addLine = this.userInfo
               }
            }
            this.id = +1
         }
         if (this.openDialog == false) {
            this.addLine = [
               {
                  country_id: null,
                  target: null,
                  comission: null,
               }
            ],
               this.id = 0
         }
      },

   },
   computed: {
      dialog_title() {
         if (!this.position_id && this.position_id !== 0) {
            return this.$i18n.t('add the target')
         } else return this.$i18n.t('edit the target')
      },
      filterCountry() {
         return this.country.filter((country) => {
            if (this.addLine.some(addLine => addLine.country_id === country.id)) {
               return country.disable = true
            } else {
               country.disable = false
               return country
            }
         })
      },
   },
   mounted() { },

   props: {
      openDialog: { default: false },
      userDialog: { default: false },
      closeDialog: { type: Function },
      closeUserDialog: { type: Function },
      rowData: { default: Object },
      userInfo: { type: Array },
      UserControlForm: { default: Object },
      position_id: { default: Number },
      backValueMethod: { type: Function },

   },
   components: { GenericInput, AnimatedLoading }
}
</script>

<style lang="scss" scoped>
.loading_component {
   padding-top: 4rem !important;
   height: 300px;
}

// Upload IMG STYLE
.image-input {
   display: block;
   height: 110px;
   width: 112px;
   cursor: pointer;
   border-radius: 50%;
   background-size: 100% 100%;
   background-position: center center;
   z-index: 1;
}

.temporary_img_parent {
   z-index: 140;
   position: absolute;
   top: 127px;
   right: 35px;
}


.temporary_img {
   width: 117px;
   max-height: 119px;
   margin-top: 1.1rem;
   margin-right: 12px;
   border-radius: 50%;
   background-color: #fff;
   background-size: 100% 100%;
   background-position: center center;

}

.file-input {
   display: none
}

// Upload IMG STYLE
</style>
