<template>
  <v-card elevation="0" class="ma-0 pa-0 px-5 sticky_header  rounded-lg">
    <v-row justify="space-between" align="center">
      <v-col cols="12" sm="auto" class="d-flex justify-center align-center">
        <div class="subtitle-2 gray8--text d-flex align-center font-weight-medium">
          <!-- {{ pageData.main }} > -->
          {{ pageData.category }} <v-icon class="mx-2" size="7">mdi-circle</v-icon>
        </div>
        <div class="text-h6 secondary--text mt-n1 text-capitalize">
          <strong>{{ pageData.isEdit ? $t('edit') : $t('add') }} {{ pageData.entityName }}</strong>
        </div>
        <slot name="rightSide"></slot>
      </v-col>
      <slot name="centerSide"></slot>
      <v-col cols="12" sm="auto" class="pa-0">
        <v-row justify="end" align="center">
          <v-col cols="12" sm="auto">
            <slot name="leftSide"></slot>

            <v-btn depressed @click="viewForm" v-if="editForm" :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg"
              color="blue4" outlined :height="40">{{ $t("edit") }}
            </v-btn>

            <v-btn depressed @click="deleteDialog = true"
              v-if="pageData.isEdit == true && $global.CheckAction(pageData.screen_code, 4) && form.active != 0 && showDeleted"
              :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg" color="error" :height="40">{{ $t("delete") }}
            </v-btn>

            <v-btn depressed @click="restoreMethod"
              v-if="pageData.isEdit == true && $global.CheckAction(pageData.screen_code, 4) && form.active == 0"
              :loading="pageData.isLoading" class="my-2 mx-2 rounded-lg accent--text" color="white" :height="40">
              {{ $t("restore") }}</v-btn>
            <slot name="left"></slot>
            <v-btn depressed @click="actionBtnClick" v-if="actionBtn == true" :loading="pageData.isLoading"
              :outlined="actionBtnOutline" :disabled="!actionBtnValid" :color="actionBtnColor" :class="actionBtnClass"
              :height="40">
              <span>{{ $t(actionBtnText) }}</span>
            </v-btn>
            <v-btn depressed @click="exitDialog = true" v-if="showClose" class="my-2 mx-2 rounded-lg" text plain
              :height="40" color="error">
              {{ $t('cancel') }}
              <!-- <v-icon right>mdi-close</v-icon> -->
            </v-btn>

            <v-btn depressed @click="save" v-if="createBtn" :min-width="90" :loading="pageData.isLoading"
              :disabled="!valid" class="my-2 mx-2 rounded-lg white--text" :height="40" color="success">{{ pageData.isEdit
                ?
                $t('save') : $t(createBtnText)
              }}</v-btn>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <ExitConfirmationModal :openDialog="exitDialog" :pageData="pageData" :closeDialog="() => exitDialog = false" />
    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="form.name ? form.name : null"
      :backValueMethod="returnedDelete"></DeleteConfirmation>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Settings");
import DeleteConfirmation from "../modals/DeleteConfirmation.vue";
import ExitConfirmationModal from "../modals/ExitConfirmationModal.vue";
export default {
  name: "ControlHeader",
  data: () => ({
    deleteDialog: false,
    exitDialog: false,
  }),
  computed: {
    ...mapState(["favourites"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  props: {
    pageData: { default: {} },
    valid: { default: false },
    form: { default: {} },
    save: { type: Function },
    deleteMethod: { type: Function },
    showDeleted: { default: true },
    restoreMethod: { type: Function },
    createBtn: { default: true },
    createBtnText: { default: "create" },
    actionBtn: { default: false },
    actionBtnValid: { default: false },
    actionBtnColor: { default: 'accent' },
    actionBtnClass: { default: "my-2 mx-2 white--text rounded-lg" },
    actionBtnClick: { type: Function },
    actionBtnText: { default: "action btn" },
    actionBtnOutline: { default: false },
    editForm: { default: false },
    viewForm: { type: Function },
    showClose: { default: true },
  },
  methods: {
    ...mapActions(["addFavourites"]),

    addToFav() {
      const fav = {
        screen_code: this.pageData.screen_code,
        name: this.pageData.entityName,
        url: this.$route.params.id ? this.pageData.controlRoute + this.$route.params.id : this.pageData.controlRoute,
      };
      // const fav = {
      //   screen_code: this.pageData.screen_code,
      //   name: this.pageData.entityName,
      //   url: this.pageData.controlRoute,
      // };
      this.addFavourites(fav).finally(() => { });
    },
    returnedDelete(status) {
      this.deleteDialog = false;
      this.deleteMethod(status);
    },

  },
  components: {
    DeleteConfirmation,
    ExitConfirmationModal
  }
};
</script>

<style scoped lang="scss"></style>
