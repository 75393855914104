<template>
    <section>
        <v-container fluid>
            <!-- Header -->
            <ControlHeader class="pt-2" :pageData="pageData" :form="form" :save="save" :valid="valid" :showDeleted="false"
                :viewForm="() => viewForm = true">
                <template v-slot:left>

                </template>
            </ControlHeader>
            <!-- End Of Header -->

            <!-- Status -->
            <v-row align="center" justify="start">
                <v-col cols="12" md="11" lg="11" class="status mb-0">
                    <div class="shape" v-for="item in status" :key="item.id" :class="status_style2(item.id)"
                        @click="handelWinDeal">
                        <div class="shape_inner subtitle-2 font-weight-bold" :class="status_style(item.id)"
                            @click="status_id = item.id"> {{ item.name }} </div>
                    </div>
                </v-col>
            </v-row>
            <!-- End Status -->

            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3">
                <v-row class="cols_container">

                    <v-col cols="8" class="first_col">

                        <!-- Summery -->
                        <v-col cols="12" v-if="pageData.isEdit && !viewForm">
                            <v-card class="light rounded-lg" elevation="0">
                                <ControlSummery :summery="summery" :loading="pageData.isLoading" :type="1" class="pt-0">
                                    <template v-slot:summerySlot="{ item }">
                                        <div v-if="item.label === 'main data'">
                                            {{ $t(item.label) }}
                                        </div>

                                        <div v-if="item.label === 'customer name'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'cell phone number'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'email'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'edit'">
                                            <v-btn fab class="secondary--text rounded-circle" height="38" width="38"
                                                v-if="item.value.responsible_seller_id === $store.state.userData.id || $store.state.userData.user_position_id === 1"
                                                @click="RouteCustomerProfile(item.value)" elevation="0" color="blue11">
                                                <img class="cursor_pointer" src="@/assets/img/svg/report_edit_icon.svg">
                                            </v-btn>
                                        </div>

                                        <div v-if="item.label === 'the address'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text" v-if="item.value !== '-'">
                                                {{ item.value }}
                                            </span>
                                            <span class="font-weight-bold black--text" v-else>
                                                {{ $t('no data') }}
                                            </span>
                                        </div>


                                        <div v-if="item.label === 'address on google map'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <div class="google_map">
                                                <a class="info--text" :href="item.value" target="_blank">
                                                    {{ item.value || $t('no data') }}
                                                </a>

                                                <v-btn fab class="info--text d-flex" height="45" width="45" elevation="0"
                                                    color="transparent" :href="item.value" target="_blank">
                                                    <v-icon size="20">mdi-open-in-new</v-icon>
                                                </v-btn>
                                            </div>

                                        </div>

                                        <div v-if="item.label === 'country'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value.name || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'the city'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value.name || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'region'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text">
                                                {{ item.value.name || $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'company size'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text" v-if="item.value !== '-'">
                                                {{ filterData(company_size, +item.value, 'name') }}
                                            </span>
                                            <span v-if="item.value === '-'" class="font-weight-bold black--text">
                                                {{ $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'company level'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <div class="d-flex align-center font-weight-bold black--text"
                                                v-if="item.value !== '-'">
                                                <span>{{ filterData(company_level, +item.value, 'name') }}</span>
                                                <img class="px-2" :src="filterData(company_level, +item.value, 'image')">
                                            </div>
                                            <span v-if="item.value === '-'" class="font-weight-bold black--text">
                                                {{ $t('no data') }}
                                            </span>
                                        </div>

                                        <div v-if="item.label === 'interest in the product'">
                                            <span class="text-overline gray4--text">{{ $t(item.label) }} </span> <br>
                                            <span class="font-weight-bold black--text" v-if="item.value.at(1)">
                                                <span v-for="(items, i) in item.value" :key="items.id">
                                                    {{ items.name }}
                                                    <span v-if="item.value.length - 1 !== i">{{ '-' }}</span>
                                                </span>
                                            </span>
                                            <span v-if="!item.value.at(1)" class="font-weight-bold black--text">
                                                {{ $t('no data') }}
                                            </span>
                                        </div>

                                    </template>
                                </ControlSummery>
                            </v-card>
                        </v-col>
                        <!-- End of Summery -->

                        <!-- main data -->
                        <v-row class="align-center light py-2 my-3 rounded-lg" v-if="viewForm">

                            <v-col cols="12">
                                <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('main data') }}</div>
                            </v-col>

                            <GenericInput type="select" :value="form.customer_id" @input="form.customer_id = $event"
                                label="customer name" :lookups="customers" selected_label="name" selected_prop="id"
                                :required="true" :clearable="true" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                                <template v-slot:append>
                                    <v-icon>mdi-magnify</v-icon>
                                </template>
                            </GenericInput>

                            <GenericInput type="number" :value="form.mobile_number" @input="form.mobile_number = $event"
                                label="mobile number" icon="mdi-cellphone" :clearable="true" :required="false"
                                :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                            </GenericInput>

                            <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                                :required="false" :clearable="true" icon="mdi-email-outline"
                                :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                            </GenericInput>

                        </v-row>
                        <!--End main data -->

                        <v-col cols="12" class="pa-0" v-if="viewForm">
                            <v-divider></v-divider>
                        </v-col>

                        <!-- comment Input -->
                        <v-row class="align-center light py-2 my-3 rounded-lg" :class="{ 'mx-3': !viewForm }">

                            <v-col cols="6">
                                <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('notes and comments') }}</div>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn class="secondary--text" :loading="false"
                                    :disabled="!pageData.isEdit && !form.customer_id" @click="add_note" elevation="0"
                                    color="blue12">
                                    {{ $t('add a comment') }}</v-btn>
                            </v-col>

                            <GenericInput type="textarea" :value="form.comment" @input="form.comment = $event"
                                label="Write your notes"
                                :keydownEnter="$route.params.id || form.customer_id ? add_note : null" :key="counterKey"
                                :rowsNumber="1" :required="false" :isLoading="false" :cols="[12, 12, 12]">
                            </GenericInput>
                        </v-row>

                        <!-- comments -->
                        <v-row class="comments-section py-2 my-3 rounded-lg" :class="{ 'mx-3': !viewForm }"
                            v-if="notes.length">
                            <v-card class="mx-auto my-1 rounded-lg" :class="{ 'failed_border': note.id == -1 }" width="100%"
                                elevation="0" v-for="(note) in notes" :key="note.id">
                                <v-list-item three-line>
                                    <v-avatar size="56">
                                        <LazyImage :height="80"
                                            :src="note.user ? $api.serverUrl + note.user.image : $api.serverUrl + $store.state.userData.image"
                                            :error="require('@/assets/img/svg/USER.svg')"
                                            :loading="require('@/assets/img/svg/USER.svg')" />
                                    </v-avatar>

                                    <v-list-item-content class="pa-2">
                                        <div class="d-flex align-center text-overline">
                                            <span class="space_letter">
                                                {{ note.created_by || $store.state.userData.name }}
                                            </span>

                                            <!-- <span v-once>-{{ note.created_at || generateDate() }}</span> -->
                                            <span v-if="note.created_at">-{{ note.created_at || generateDate() }}</span>

                                            <span class="px-2" v-if="note.loader && note.id == 0">
                                                <v-progress-circular value="20" size="15" width="2"
                                                    indeterminate></v-progress-circular>
                                            </span>

                                            <span class="px-2" v-if="!note.loader && note.created_at">
                                                <v-icon color="success" small>mdi-check-circle-outline</v-icon>
                                            </span>

                                            <span class="px-2" v-if="note.id == -1">
                                                <v-btn color="red12" depressed :min-width="74" height="22"
                                                    class="font-weight-bold rounded-lg  error--text">
                                                    {{ $t('did not send') }}
                                                </v-btn>
                                            </span>

                                            <!-- <span class="px-2 cursor_pointer" v-if="note.id == -1">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="success" dark v-bind="attrs" v-on="on"
                                                            @click="handelResend">
                                                            mdi mdi-reload
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ $t('resend') }}</span>
                                                </v-tooltip>
                                            </span> -->

                                        </div>
                                        <v-list-item-subtitle class="gray11 pa-3 rounded-lg font-weight-bold">
                                            {{ note.comment || null }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-row>

                        <!-- no comments -->
                        <v-row class="no_comments_container" v-if="!notes.length">
                            <v-col cols="12" class="no_comments">
                                <div class="d-flex flex-column align-center">
                                    <img src="@/assets/img/svg_icons/Chat-1.svg" alt="">
                                    <span class="gray7--text font-weight-bold mt-7 text-h5">
                                        {{ $t('There are no notes or comments') }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Call history -->
                    <v-col cols="3">
                        <v-row class="align-center light py-2 my-3 rounded-lg">

                            <v-col cols="6" class="py-1">
                                <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('Call history') }}</div>
                            </v-col>

                            <v-col cols="6" class="d-flex justify-end py-1">
                                <v-btn class="secondary--text" :disabled="!pageData.isEdit && !form.customer_id"
                                    @click="AddCallsDialog = true" elevation="0" color="blue12">
                                    {{ $t('Add contact') }}</v-btn>
                            </v-col>

                            <!-- calls log -->
                            <v-col cols="12" v-if="calls_log.length" class="col_summery pt-0">
                                <div class="calls_log d-flex my-2 justify-space-between" v-for="(call, i) in calls_log"
                                    :key="i">

                                    <div class="date">
                                        <span class="text-caption">{{ $t('date') }} </span><br>
                                        <span class="font-weight-bold text-button">{{ call.date }}</span>
                                    </div>

                                    <div class="hour">
                                        <span class="text-caption">{{ $t('the time') }}</span><br>
                                        <span class="font-weight-bold text-button"> {{ call.hour }}</span>
                                    </div>

                                    <div class="answer">
                                        <span class="text-caption">{{ $t('Possibility of response') }} </span><br>
                                        <span class="font-weight-bold text-button">
                                            {{ call.possibility_of_reply.name }}
                                        </span>
                                    </div>

                                    <div class="call_duration">
                                        <span class="text-caption">{{ $t('Call duration (in minutes)') }} </span><br>
                                        <span class="font-weight-bold text-button">{{ call.duration || '0' }}</span>
                                    </div>

                                    <div class="added_by">
                                        <span class="text-caption">{{ $t('The caller') }}</span><br>
                                        <span class="font-weight-bold text-button">{{ call.created_by }}</span>
                                    </div>
                                </div>

                            </v-col>

                            <!-- no calls -->
                            <v-col cols="12" v-if="!calls_log.length" class="no_calls">
                                <div class="d-flex flex-column align-center">
                                    <img src="@/assets/img/svg_icons/phonecall-blocked.svg" alt="">
                                    <span class="gray7--text font-weight-bold mt-7 text-h5">
                                        {{ $t('There are no calls') }}
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- End Call history -->

                </v-row>

            </v-form>
            <!-- End of Form -->

            <AddCallsDialog :openDialog="AddCallsDialog" :closeDialog="() => AddCallsDialog = false"
                :backValueMethod="backValueMethod" :customer_id="form.customer_id" />

            <WinTheDealDialog :openDialog="WinTheDealDialog" :closeDialog="() => WinTheDealDialog = false"
                :customer_id="form.customer_id" :saveAction="save" />
        </v-container>
    </section>
</template>
 
 
 
<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlSummery from "@/components/ui/ControlSummery.vue";
import LazyImage from "@/components/ui/LazyImage.vue";
import AddCallsDialog from "./AddCallsDialog.vue";
import WinTheDealDialog from "./WinTheDealDialog.vue";


export default {
    name: "CallsAndFollowUpControl",

    data: () => ({
        pageData: {
            count: 0,
            screen_code: "01-002",
            url: null,
            controlRoute: "calls_followup/calls_followup-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isEdit: false,
            editIsLoading: false,
        },
        viewForm: false,
        summery: [],
        status_id: 1,
        counterKey: 1,
        status: [],
        notes: [],
        valid: false,
        AddCallsDialog: false,
        WinTheDealDialog: false,
        loading_comment: false,
        failed_comment: false,
        calls_log: [],
        answerArr: [],
        company_level: [],
        company_size: [],
        customers: [],
        clientData: {},
        commitData: {},
        form: {
            client_id: null,
            customer_id: null,
            mobile_number: null,
            email: null,
            notes: null
        },
    }),
    components: {
        GenericInput,
        ControlHeader,
        ControlSummery,
        LazyImage,
        AddCallsDialog,
        WinTheDealDialog
    },
    computed: {
        status_style() {
            return (id) => {
                if (this.status_id === id) {
                    switch (this.status_id) {
                        case 1: return 'orange6--text yellow13'
                        case 2: return 'blue13--text blue14'
                        case 3: return 'yellow18--text yellow11'
                        case 4: return 'green13--text green12'
                        case 5: return 'gray12--text green13'
                        case 6: return 'red13--text red12'
                        case 7: return 'gray12--text red14'
                    }
                }
            }
        },
        status_style2() {
            return (id) => {
                if (this.status_id === id) {
                    switch (this.status_id) {
                        case 1: return 'orange6 z_index'
                        case 2: return 'blue13 z_index'
                        case 3: return 'yellow18 z_index'
                        case 4: return 'green13 z_index'
                        case 5: return 'gray12 z_index'
                        case 6: return 'red13 z_index'
                        case 7: return 'gray12 z_index'
                    }
                }
            }
        },
        filterData() {
            return (arr, id, key) => arr.filter((arr) => arr.id === id)[0][key]
        },
        // generateDate() {
        //     const now = new Date();
        //     const formattedDateTime = now.toLocaleString('en-US', {
        //         year: 'numeric',
        //         month: '2-digit',
        //         day: '2-digit',
        //         hour: '2-digit',
        //         minute: '2-digit',
        //         second: '2-digit',
        //         hourCycle: 'h23'
        //     }).replace(',', '');
        //     return () => formattedDateTime
        // },

    },
    watch: {
        'form.customer_id'() {
            // fill email and mobile number inputs
            if (this.form.customer_id) {
                let filterCustomer = this.customers.filter(e => e.id === this.form.customer_id)[0]
                this.form.mobile_number = filterCustomer.phone
                this.form.email = filterCustomer.email
            } else {
                this.form.mobile_number = null
                this.form.email = null
            }
        }
    },
    mounted() {
        this.pageMainData()
        this.getData()
        this.company_level = [
            {
                id: 1,
                name: this.$i18n.t('bad'),
                image: require('@/assets/img/svg/Star 1.svg')
            },
            {
                id: 2,
                name: this.$i18n.t('middle'),
                image: require('@/assets/img/svg/Star 2.svg')
            },
            {
                id: 3,
                name: this.$i18n.t('good'),
                image: require('@/assets/img/svg/Star 3.svg')
            },
            {
                id: 4,
                name: this.$i18n.t('very good'),
                image: require('@/assets/img/svg/Star 4.svg')
            },
            {
                id: 5,
                name: this.$i18n.t('amazing'),
                image: require('@/assets/img/svg/Star 5.svg')
            },
        ]
        this.company_size = [
            {
                id: 1,
                name: '0-50',
            },
            {
                id: 2,
                name: '50-100',
            },
            {
                id: 3,
                name: '100-500',
            },
            {
                id: 4,
                name: '500-1000',
            },
            {
                id: 5,
                name: '1000-10000',
            },
        ]
        this.answerArr = [
            {
                id: 1,
                name: this.$i18n.t('answered'),
            },
            {
                id: 3,

                name: this.$i18n.t("Busy (not available now)"),
            },
            {
                id: 4,

                name: this.$i18n.t("closed or unavailable"),
            },
            {
                id: 5,
                name: this.$i18n.t("wrong number"),
            },
        ]
        window.onbeforeunload = (event) => {
            // prevent page reload until comment save
            if (this.notes[0].loader) {
                event.preventDefault();
                event.returnValue = '';
            }
        }
    },

    methods: {
        generateDate() {
            const now = new Date();
            const formattedDateTime = now.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hourCycle: 'h23'
            }).replace(',', '');
            return formattedDateTime
        },
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
            this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
        },
        handelWinDeal() {
            if (this.status_id == 5 && this.form.customer_id && !this.WinTheDealDialog) {
                this.WinTheDealDialog = true
            }
            if (this.status_id == 5 && this.pageData.isEdit && !this.WinTheDealDialog) {
                this.WinTheDealDialog = true
            }
        },
        getData() {
            if (this.$route.params.id) {
                if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
                    this.pageData.isEdit = true;
                    this.pageData.editIsLoading = true;
                    this.pageData.isLoading = true;
                    this.$api.GET_METHOD(`client/${this.$route.params.id}`).then((response) => {
                        this.pageData.editIsLoading = false;
                        this.pageData.isLoading = false;
                        if (response.check) {
                            console.log("response", response);
                            this.clientData = response.data.client;
                            this.status_id = response.data.client.status
                            this.status = response.data.cases_count
                            this.notes = response.data.client.comments
                            this.calls_log = response.data.calls

                            // fix api problem when delete non mandatory data
                            if (response.data.client.company_level === '0') {
                                response.data.client.company_level = null
                            }
                            this.summery = [
                                {
                                    label: 'main data',
                                    value: "response.data.data.name" || '-',
                                    type: 'slot',
                                    class: 'font-weight-bold text-subtitle-2 gray4--text',
                                    cols: null, sm: null, md: 12, lg: 12, xl: 12,
                                },
                                {
                                    label: 'customer name',
                                    value: response.data.client.name || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 3, md: 3, lg: 3, xl: 3,
                                },
                                {
                                    label: 'cell phone number',
                                    value: response.data.client.phone || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 3, md: 3, lg: 3, xl: 3,
                                },
                                {
                                    label: 'email',
                                    value: response.data.client.email || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 3,
                                },
                                {
                                    label: 'edit',
                                    value: response.data.client || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto',
                                },
                                {
                                    label: 'the address',
                                    value: response.data.client.address || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 6, md: 6, lg: 6, xl: 6,
                                },
                                {
                                    label: 'address on google map',
                                    value: response.data.client.google_map || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 6, md: 6, lg: 6, xl: 6,
                                },
                                {
                                    label: 'country',
                                    value: response.data.client.country || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },
                                {
                                    label: 'the city',
                                    value: response.data.client.city || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },
                                {
                                    label: 'region',
                                    value: response.data.client.area || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },
                                {
                                    label: 'company size',
                                    value: response.data.client.company_size || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },
                                {
                                    label: 'company level',
                                    value: response.data.client.company_level || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },
                                {
                                    label: 'interest in the product',
                                    value: response.data.client.products || '-',
                                    type: 'slot',
                                    class: 'text-button',
                                    cols: null, sm: 4, md: 4, lg: 4, xl: 4,
                                },

                            ]
                        }
                    })
                } else {
                    this.$router.push('/')
                }
            }
            else {
                if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
                    this.pageData.isLoading = true;
                    this.viewForm = true;
                    this.$api.GET_METHOD(`clients/new`).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            console.log("response clients/new", response);
                            this.status = response.data.cases;
                            this.customers = response.data.clients;
                        }

                    })
                } else {
                    this.$router.push('/')
                }

            }
        },
        save() {
            if (this.$refs.form.validate()) {
                let createCustomerData = {
                    status: this.status_id,
                    client_id: this.$route.params.id
                }
                this.pageData.isLoading = true;
                if (this.pageData.isEdit == true) {
                    // this.form._method = 'PUT';
                    this.$api.POST_METHOD(`client/update_status`, createCustomerData).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            // this.$router.push(this.pageData.url)
                            this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                        }
                    })
                }
                else {
                    let createCustomerData = {
                        status: this.status_id,
                        client_id: this.form.customer_id
                    }
                    this.$api.POST_METHOD(`client/update_status`, createCustomerData).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.$router.push(this.pageData.url)
                        }
                    })
                }
            }
        },
        backValueMethod(calls_log) {
            this.calls_log.unshift({ ...calls_log })
        },
        add_note() {
            // check if input has value
            if (typeof this.form.comment === 'string' && this.form.comment.trim().length > 0) {
                this.loading_comment = true
                this.commitData = { id: 0, comment: this.form.comment, client_id: null, loader: false }
                this.commitData.loader = true
                this.$route.params.id ? this.commitData.client_id = this.$route.params.id : this.commitData.client_id = this.form.customer_id
                this.notes.unshift(this.commitData)
                this.form.comment = null
                this.counterKey++
                this.$api.POST_METHOD(`comment/store`, this.commitData)
                    .then((response) => {
                        // this.form.comment = null
                        this.commitData.loader = false
                        this.loading_comment = false
                        if (response.check) {
                            this.failed_comment = false
                            this.notes[0] = response.data
                            // this.form = { ...this.form }
                        } else {
                            this.notes[0].id = -1
                            this.failed_comment = true
                            // this.notes.shift()
                            this.form.comment = null
                            // this.form = { ...this.form }
                        }

                    })
            }
        },
        handelResend() {
            // check if input has value
            if (typeof this.form.comment === 'string' && this.form.comment.trim().length > 0) {
                this.loading_comment = true
                this.commitData = { ...this.notes[0] }
                this.commitData.loader = true
                this.$route.params.id ? this.commitData.client_id = this.$route.params.id : this.commitData.client_id = this.form.customer_id
                this.form.comment = null
                this.counterKey++
                this.$api.POST_METHOD(`comment/store`, { ...this.notes[0] })
                    .then((response) => {
                        // this.form.comment = null
                        this.commitData.loader = false
                        this.loading_comment = false
                        if (response.check) {
                            this.notes[0].id = 0
                            this.failed_comment = false
                            this.notes[0] = { ...this.notes[0] }
                        } else {
                            this.notes[0].id = -1
                            this.failed_comment = true
                            // this.notes.shift()
                            this.form.comment = null
                        }
                        // this.form = { ...this.form }
                    })
            }
        },
        // add_note() {
        //     // check if input has value
        //     if (typeof this.form.comment === 'string' && this.form.comment.trim().length > 0) {
        //         this.loading_comment = true
        //         let commitData = { comment: this.form.comment, client_id: null }
        //         this.$route.params.id ? commitData.client_id = this.$route.params.id : commitData.client_id = this.form.customer_id
        //         this.notes.unshift(commitData)
        //         this.form.comment = null
        //         this.form = { ...this.form }
        //         this.$api.POST_METHOD(`comment/store`, commitData)
        //             .then((response) => {
        //                 this.loading_comment = false
        //                 if (response.check) {
        //                     // this.notes.unshift(response.data)
        //                     // this.form.comment = null
        //                 }
        //             })
        //     }
        // },
        RouteCustomerProfile(row) {
            console.log('row', row);
            this.$router.push(`/customers/customers-control/${row.id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.cols_container {
    display: flex;
    column-gap: 15px;
    height: 100vh;

    .col_summery {
        max-height: 957px;
        overflow: auto;
    }

    div span {
        letter-spacing: 0px !important;
    }

    .col.col-3 {
        flex: 1;
        max-width: 100%;

        .row {
            align-content: flex-start;
            background: #f9f9f9;
            position: relative;
            height: 100%;

            .no_calls {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }

    .first_col {
        background: whites;
    }

    .comments-section {
        max-height: 508px;
        overflow: scroll;
    }

}

.status {
    display: flex;

    .shape {
        position: relative;
        display: inline-block;
        background: #D9D9D9;
        box-sizing: border-box;
        height: 55px;
        width: 305px;
        -webkit-clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
        clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
        margin-right: -80px;
        cursor: pointer;

        .shape_inner {
            position: absolute;
            background: white;
            top: 1px;
            left: 1.1%;
            width: 98%;
            height: 53px;
            -webkit-clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
            clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 45px;
        }
    }

}

.v-application.v-application--is-rtl {
    .status {
        .shape:first-child {
            margin-right: unset;
        }
    }
}

.calls_log {
    flex-wrap: wrap;
    gap: 1px;
    background: white;
    padding: 9px;
    border-radius: 12px;

    div {
        min-width: 190px;
    }

    div span {
        letter-spacing: 0px !important;
    }
}



.no_comments_container {
    position: relative;
    width: 100%;
    height: 250px;

    .no_comments {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

    }
}

.space_letter {
    letter-spacing: 0 !important;
    font-weight: 400;
}

.google_map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 330px;
    max-width: 100%;

    a:nth-child(1) {
        white-space: nowrap;
        width: 266px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.z_index {
    z-index: 1;
}

.failed_border {
    border: 1px solid red;
}
</style>
 