<template>
    <v-dialog v-model="openDialog" persistent width="1340">
        <v-card min-height="765">
            <v-card-actions class="sticky">
                <v-card-text class="font-weight-bold text-h6 text-capitalize secondary--text">
                    <div v-if="data.action_type === 'customer_without_calls'">
                        {{ $t("customers registered during the period") }}
                    </div>
                    <div v-else>{{ $t("calls during the period") }}</div>
                </v-card-text>

                <v-btn color="red15" depressed :width="106" height="48" @click="closeDialog"
                    class="font-weight-bold rounded-lg  red4--text" :loading="false">
                    {{ $t('back') }}
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12">
                        <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                            :option="pageData.options" :pageData="pageData">
                            <template v-slot:td="{ row, header }">
                                <a v-if="header.text == 'customer name'" class="text-decoration-underline info--text"
                                    @click="edit(row)">
                                    {{ row.name }}
                                </a>

                                <div v-if="header.text === 'country'">
                                    {{ row.country.name || '' }}
                                </div>
                                <div v-if="header.text === 'the city' && row.city">
                                    {{ row.city.name || '' }}
                                </div>
                                <div v-if="header.text === 'region' && row.area">
                                    {{ row.area.name || '' }}
                                </div>

                                <div v-if="header.text === 'number of calls'" class="actions cursor_pointer d-flex">
                                    <v-menu offset-y open-on-hover>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">{{ row.calls_count }}</div>
                                        </template>
                                        <v-list min-width="320" v-if="row.calls.at(0)">
                                            <v-list-item class="flex-wrap text-center" v-for="calls in row.calls"
                                                :key="calls.id">
                                                <v-list-item-title class="text-caption font-weight-bold">
                                                    {{ calls.created_by }} - {{ calls.updated_at }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                        </DynamicTable>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
export default {
    name: "CustomersReportsDialog",
    data: () => ({
        pageData: {
            screen_code: "01-003",
            url: null,
            controlRoute: "/reports/sales_agent_statement",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        apiUrl: ""
    }),
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        data: { default: Object },
    },
    computed: {},
    mounted() {
    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.handelTable()
                this.getData();
            }
        }
    },
    methods: {
        handelTable() {
            if (this.data.action_type === 'customer_without_calls') {
                this.apiUrl = 'reports/seller/registered_clients'

                this.pageData.tableHeader = [
                    { text: "serial", key: "id", type: 'text', classes: "" },
                    { text: "customer name", key: "name", type: 'text', classes: "" },
                    { text: "cell phone", key: "phone", type: 'text', classes: "" },
                    { text: "country", key: "country", type: 'slot', classes: "" },
                    { text: "the city", key: "city", type: 'slot', classes: "" },
                    { text: "region", key: "area", type: 'slot', classes: "" },
                ];
            } else {
                this.apiUrl = 'reports/seller/registered_calls'

                this.pageData.tableHeader = [
                    { text: "serial", key: "id", type: 'text', classes: "" },
                    { text: "customer name", key: "name", type: 'slot', classes: "" },
                    { text: "cell phone", key: "phone", type: 'text', classes: "" },
                    { text: "country", key: "country", type: 'slot', classes: "" },
                    { text: "the city", key: "city", type: 'slot', classes: "" },
                    { text: "region", key: "area", type: 'slot', classes: "" },
                    { text: "transaction amount", key: "deals_sum_amount", type: 'float', classes: "" },
                    { text: "number of calls", key: "calls_count", type: 'slot', classes: "" },
                    { text: "response status", key: "last_call_status", type: 'text', classes: "" },
                ];
            }

        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                this.pageData.isLoading = true;
                this.$api.GET_METHOD(`${this.apiUrl}?seller_id=${this.data.seller_id}&from=${this.data.from}&to=${this.data.to}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.pageData.rows = response.data;
                            this.apiUrl = ""
                        }
                    });
            }
        },
        edit(row) {
            console.log("edit", row);
            this.$router.push(`/calls_followup/calls_followup-control/` + row.id);
        },

    },

    components: {
        DynamicTable,
        //  Pagination 
    }
}
</script>

<style lang="scss" scoped>
.sticky {
    position: sticky;
    z-index: 999;
    background: white;
    top: 0px;
}
</style>
