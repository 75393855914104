<template>
    <section>
        <v-container fluid>
            <!-- Header -->
            <EntityHeader class="pt-3" :pageData="pageData" :getData="getData" :getSearch="getSearch" createBtnText="add"
                :showDeleted="false">
                <template v-slot:rightSide>
                    <v-badge dot left color="green" :value="filter_status">
                        <v-btn fab class="rounded-circle" height="38" width="38" @click="openFilterDialog = true"
                            elevation="0">
                            <img class="cursor_pointer" src="@/assets/img/svg_icons/filter.svg">
                        </v-btn>
                    </v-badge>
                </template>
            </EntityHeader>
            <!-- End Of Header -->

            <!-- Status -->
            <v-row align="center" class="mt-2" v-if="status.length">
                <v-col cols="12" md="1" lg="1">
                    <div class="font-weight-bold ">{{ $t('cases') | capitalize }}</div>
                </v-col>
                <v-col cols="12" md="11" lg="11" class="status">
                    <div class="shape" v-for="item in status" :key="item.id" :class="status_style2(item.id)">
                        <div class="shape_inner subtitle-2 font-weight-bold" :class="status_style(item.id)"
                            @click="status_id = item.id"> {{ item.name }}
                            <span class="px-1">
                                ({{ item.count }})
                            </span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <!-- End Status -->

            <v-row>
                <v-col cols="12" sm="12">
                    <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                        :option="pageData.options" :editValueMethod="edit" :pageData="pageData"
                        :deleteValueMethod="deleteMethod" :restoreValueMethod="restoreMethod">
                        <template v-slot:td="{ row, header }">
                            <a v-if="header.text == 'customer name'" class="text-decoration-underline info--text"
                                @click="edit(row)">
                                {{ row.name }}
                            </a>

                            <div v-if="header.text === 'country'">
                                {{ row.country.name || '' }}
                            </div>
                            <div v-if="header.text === 'the city' && row.city">
                                {{ row.city.name || '' }}
                            </div>
                            <div v-if="header.text === 'region' && row.area">
                                {{ row.area.name || '' }}
                            </div>

                            <div v-if="header.text === 'number of calls'" class="actions cursor_pointer d-flex">
                                <v-menu offset-y open-on-hover transition="slide-x-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">{{ row.calls_count }}</div>
                                    </template>
                                    <v-list min-width="320" v-if="row.calls.at(0)">
                                        <v-list-item class="flex-wrap text-center" v-for="calls in row.calls"
                                            :key="calls.id">
                                            <v-list-item-title class="text-caption font-weight-bold">
                                                {{ calls.created_by }} - {{ calls.updated_at }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <div v-if="header.key == 'added by'">
                                <v-menu offset-y open-on-hover transition="slide-x-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="gray"
                                            class="cursor_pointer mx-1">mdi-account-circle-outline</v-icon>
                                    </template>
                                    <v-list min-width="220">
                                        <v-list-item class="flex-wrap text-center">
                                            <v-list-item-title class="text-caption text-start">
                                                <div>{{ $t("added by") }}: {{ row.created_by }}</div>
                                                <div class="mt-3">
                                                    {{ $t("the responsible representative") }}: {{ row.seller.name }}
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </template>
                    </DynamicTable>
                </v-col>
            </v-row>

            <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
                <v-col cols="12" sm="12">
                    <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                        :isLoading="pageData.isLoading"></Pagination>
                </v-col>
            </v-row>
            <FilterDialog :openDialog="openFilterDialog" :closeDialog="() => openFilterDialog = false"
                :returnFilterData="returnFilterData" />
        </v-container>

    </section>
</template>
  
<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import FilterDialog from './FilterDialog.vue';
export default {
    name: "CallsAndFollowUpEntity",

    data: () => ({
        pageData: {
            screen_code: "01-002",
            url: null,
            controlRoute: "calls_followup/calls_followup-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: new URLSearchParams(),
        },
        row_id: "",
        openDialog: false,
        openFilterDialog: false,
        filter_status: false,
        status: [],
        status_id: 1,
        from: null,
        to: null,
        call_response_type_id: null,
        seller_id: null,
    }),
    components: {
        EntityHeader,
        Pagination,
        DynamicTable,
        FilterDialog,
    },
    computed: {
        status_style() {
            return (id) => {
                if (this.status_id === id) {
                    switch (this.status_id) {
                        case 1: return 'orange6--text yellow13'
                        case 2: return 'blue13--text blue14'
                        case 3: return 'yellow18--text yellow11'
                        case 4: return 'green13--text green12'
                        case 5: return 'gray12--text green13'
                        case 6: return 'red13--text red12'
                        case 7: return 'gray12--text red14'
                    }
                }
            }
        },
        // for test style (optional)
        status_style2() {
            return (id) => {
                if (this.status_id === id) {
                    switch (this.status_id) {
                        case 1: return 'orange6 z_index'
                        case 2: return 'blue13 z_index'
                        case 3: return 'yellow18 z_index'
                        case 4: return 'green13 z_index'
                        case 5: return 'gray12 z_index'
                        case 6: return 'red13 z_index'
                        case 7: return 'gray12 z_index'
                    }
                }
            }
        }
    },

    mounted() {
        this.status_id = +this.$router.currentRoute.query.status || 1
        console.log(!this.$router.currentRoute.query.status);
        this.queryParams();
        this.pageMainData();
        this.getData();
        this.filter_status = this.$router.currentRoute.query.filter_status || false
    },
    watch: {
        $route() {
            // this.pageData.queryParam = new URLSearchParams().toString();
            this.queryParams();
            this.pageMainData();
            this.getData();
        },
        status_id() {
            if (this.status_id) {
                this.pageData.queryParam.set("page", 1);
                this.pageData.queryParam.set("rows", 15);
                this.pageData.queryParam.set("status", this.status_id);
                this.pageData.queryParam.set("from", this.from || '');
                this.pageData.queryParam.set("to", this.to || '');
                this.pageData.queryParam.set("call_response_type_id", this.call_response_type_id || '');
                this.pageData.queryParam.set("seller_id", this.seller_id || '');
                this.pageData.queryParam.set("filter_status", this.filter_status || '');
                console.log("3");
                this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });
            }
        }
    },
    methods: {
        queryParams() {
            this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
            this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
            this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
            this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
            this.pageData.queryParam = new URLSearchParams(this.$store.state.activeScreen.requestParam || '');
        },
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).main_title;
            this.pageData.category = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).cat_title;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            if (this.status_id === 5) {
                this.pageData.tableHeader = [
                    { text: "serial", key: "id", type: 'text', classes: "" },
                    { text: "added date", key: "date", type: 'text', classes: "" },
                    { text: "customer name", key: "name", type: 'slot', classes: "" },
                    { text: "cell phone", key: "phone", type: 'text', classes: "" },
                    { text: "country", key: "country", type: 'slot', classes: "" },
                    { text: "the city", key: "city", type: 'slot', classes: "" },
                    { text: "region", key: "area", type: 'slot', classes: "" },
                    { text: "added by", key: "created_by", type: 'text', classes: "" },
                    { text: "transaction amount", key: "deals_sum_amount", type: 'float', classes: "" },
                    { text: "number of calls", key: "calls_count", type: 'slot', classes: "" },
                    { text: "response status", key: "last_call_status", type: 'text', classes: "" },
                ];
            } else {
                this.pageData.tableHeader = [
                    { text: "serial", key: "id", type: 'text', classes: "" },
                    { text: "added date", key: "date", type: 'text', classes: "" },
                    { text: "customer name", key: "name", type: 'slot', classes: "" },
                    { text: "cell phone", key: "phone", type: 'text', classes: "" },
                    { text: "country", key: "country", type: 'slot', classes: "" },
                    { text: "the city", key: "city", type: 'slot', classes: "" },
                    { text: "region", key: "area", type: 'slot', classes: "" },
                    { text: "number of calls", key: "calls_count", type: 'slot', classes: "" },
                    { text: "response status", key: "last_call_status", type: 'text', classes: "" },
                    { text: "", key: "added by", type: 'slot', classes: "" },
                ];
            }
            this.pageData.options = {
                print: this.$global.CheckAction(this.pageData.screen_code, 5),
                delete: this.$global.CheckAction(this.pageData.screen_code, 4),
                edit: this.$global.CheckAction(this.pageData.screen_code, 3),
                restore: this.$global.CheckAction(this.pageData.screen_code, 4),
                view: this.$global.CheckAction(this.pageData.screen_code, 1),
                switch: false,
                selectRow: false,
                sortBy: "id",
                tableHeader: false,
                searchInput: false,
                printButton: false,
                handleClickRow: false,
            };
        },
        getSearch(word) {
            this.pageData.queryParam.set("word", word || '')
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });
        },
        getData() {
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                console.log('if', this.pageData.screen_code);
                this.pageData.isLoading = true;
                this.pageData.queryParam.set("status", this.status_id)
                this.$api.GET_METHOD(`follow-up?${this.pageData.queryParam.toString()}`).then((response) => {
                    this.pageData.isLoading = false;
                    if (response.check) {
                        this.$store.state.userData.total_followup = response.data.total
                        this.pageData.entityName =
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                        this.pageData.rows = response.data.clients.data;
                        this.pageData.page = response.data.clients.current_page;
                        this.status = response.data.cases_count;
                        this.pageData.pagination = {
                            page: response.data.clients.current_page,
                            totalPages: response.data.clients.last_page,
                            per_page: response.data.clients.per_page,
                            totalRows: response.data.clients.total,
                        };

                    }
                });
            } else {
                this.$router.push("/");
            }
        },
        changePage(page, limit) {
            this.pageData.queryParam.set("page", page || 1);
            this.pageData.queryParam.set("rows", limit || 15);
            this.pageData.queryParam.set("word", this.pageData.search || "");
            this.pageData.queryParam.set("status", this.status_id);
            this.pageData.queryParam.set("from", this.from || '');
            this.pageData.queryParam.set("to", this.to || '');
            this.pageData.queryParam.set("call_response_type_id", this.call_response_type_id || '');
            this.pageData.queryParam.set("seller_id", this.seller_id || '');
            this.pageData.queryParam.set("filter_status", this.filter_status || '');
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });
        },
        deleteMethod(id) {
            console.log(id);
            this.$api.POST_METHOD(`follow-up/${id}`, { _method: "delete" }).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        restoreMethod(row) {
            this.$api.POST_METHOD(`follow-up_toggle_active/${row.id}`, null).then((response) => {
                if (response.check) {
                    this.getData();
                }
            });
        },
        returnFilterData(filter) {
            console.log("filter", filter);
            this.from = filter.from
            this.to = filter.to
            this.call_response_type_id = filter.call_response_type_id
            this.seller_id = filter.seller_id
            this.filter_status = filter.filter_status
            this.pageData.queryParam.set("status", this.status_id);
            this.pageData.queryParam.set("from", this.from || '');
            this.pageData.queryParam.set("to", this.to || '');
            this.pageData.queryParam.set("call_response_type_id", this.call_response_type_id || '');
            this.pageData.queryParam.set("seller_id", this.seller_id || '');
            this.pageData.queryParam.set("filter_status", this.filter_status || '');
            // this.$global.StoreFilter({ ...filter }, 'filter')
            this.$router.push(this.pageData.url + '?' + this.pageData.queryParam.toString()).catch(() => { });

        },
        edit(row) {
            console.log("edit", row);
            this.$router.push(`/${this.pageData.controlRoute}/` + row.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    display: flex;

    .shape {
        position: relative;
        display: inline-block;
        background: #D9D9D9;
        box-sizing: border-box;
        height: 55px;
        width: 305px;
        -webkit-clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
        clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
        margin-right: -80px;
        cursor: pointer;

        .shape_inner {
            position: absolute;
            background: white;
            top: 1px;
            left: 1.1%;
            width: 98%;
            height: 53px;
            -webkit-clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
            clip-path: polygon(100% 0%, 96% 50%, 100% 100%, 25% 100%, 21% 50%, 25% 0%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 45px;
        }
    }

}

.v-application.v-application--is-rtl {
    .status {
        .shape:first-child {
            margin-right: unset
        }
    }
}

@media (max-width: 1660px) {
    .status {
        .shape {
            width: 252px;
        }
    }
}


.z_index {
    z-index: 1;
}
</style>
  