<template>
    <v-dialog v-model="openDialog" max-width="340" persistent>
        <v-card relative class="pa-5">
            <v-row align="center">
                <v-col cols="12">
                    <span class="font-weight-bold red--text text-h6">{{ $t("exit") }}</span>
                </v-col>
                <v-col cols="12">
                    <p class="subtitle-1 font-weight-medium">
                        {{ $t("Are you sure you will exit, all the data you entered will be deleted") }}
                    </p>
                </v-col>
                <v-col cols="6">
                    <v-btn depressed text @click="cancelControl" width="100%" height="36"
                        class="rounded-lg font-weight-bold error--text red12">
                        {{ $t('exit') }}
                    </v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn color="gray1" depressed outlined width="100%" height="36" @click="closeDialog"
                        class="rounded-lg font-weight-bold  ">
                        {{ $t('stay here') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ExitConfirmationModal",
    props: {
        openDialog: { default: false },
        pageData: { default: null },
        closeDialog: { type: Function },
    },
    methods: {
        backRoute() {
            this.$router.go(-1)
        },
        cancelControl() {
            if (this.pageData.isEdit) {
                this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
            } else {
                this.$router.push(this.$store.state.activeScreen.url)
            }
        },
    }
}
</script>

<style lang="scss" scoped></style>
