<template>
   <v-dialog v-model="openDialog" persistent max-width="550">
      <AnimatedLoading classes="white ma-auto loading_component" :hideText="false" :isLoading="isLoading"
         v-if="isLoading" />

      <v-card relative class="pa-5" v-if="!isLoading">
         <v-form ref="form" v-model="valid">
            <div :key="id">
               <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                  color="red5 lighten-1" text @click="close">
                  <v-icon color="red5 lighten-1">mdi-close</v-icon>
               </v-btn>
               <v-card-title class="text-h6 text-center font-weight-bold">
                  <h5 class="d-flex font-weight-bold text-h6 justify-end">
                     {{ dialog_title | capitalize }}
                  </h5>
               </v-card-title>

               <v-row class="mt-3">
                  <v-col cols="4" class="ps-8">
                     <div class="upload_image" v-if="!isLoading">
                        <p class="ms-2" style="font-size:11px">{{ $t("add profile picture") | capitalize }}</p>

                        <div style="z-index:1">
                           <image-input v-model="form.image" />
                        </div>

                        <div :loading="loading" class="image-input" :style="{ 'background-image': `url(${form.image})` }"
                           @click="chooseImage">

                           <span v-if="!form.image" class="ms-2">
                              <img src="@/assets/img/svg/profileBig.svg" alt="" />
                           </span>
                           <input class="file-input" ref="fileInput" type="file" @input="employee_image_method">
                        </div>

                        <v-btn v-if="form.image" class="error--text mt-8" :loading="loading" :width="120" depressed
                           style="border-radius: 14px" @click="reset">{{ $t("delete image") }}
                        </v-btn>

                        <span class="temporary_img_parent" :style="$vuetify.rtl ? '' : 'left:40px'">
                           <img :src="$store.state.endpointURL + form.image" alt="" style="" class="temporary_img" />

                        </span>
                     </div>

                  </v-col>

                  <v-col cols="8" :key="id">
                     <GenericInput class="mb-3" type="text" :value="form.name_ar" @input="form.name_ar = $event"
                        label="person name in Arabic" :required="true" :isLoading="false" :cols="[12, 12, 12]" />

                     <GenericInput class="mb-3" type="text" :value="form.name_en" @input="form.name_en = $event"
                        label="personal name english" :required="true" :isLoading="false" :cols="[12, 12, 12]" />

                     <GenericInput class="mt-4" :lookups="position" v-if="$store.state.userData.user_position_id === 1"
                        type="select" :value="form.user_position_id" @input="form.user_position_id = $event"
                        :disabled="position_id === 1" label="position" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="positionLoading || isLoading"
                        :cols="[12, 12, 12]" />

                     <GenericInput class="mb-3 mt-3" type="text" :disabled="position_id !== null" :value="form.username"
                        @input="form.username = $event" label="username" :validation_type="$global.usernameRule"
                        :required="true" :isLoading="false" :cols="[12, 12, 12]" />

                     <GenericInput class="mb-3" type="password" :value="form.password" @input="form.password = $event"
                        label="password" :required="!position_id" :isLoading="false" :cols="[12, 12, 12]" />

                  </v-col>

               </v-row>
               <v-row class="mt-5" align="center" justify="center">
                  <v-col cols="4">

                     <v-btn class="error--text font-weight-bold " @click="close" :width="150" depressed>
                        {{ $t("exit") }}
                     </v-btn>
                  </v-col>

                  <v-col cols="8" :key="id">
                     <v-btn v-if="!form.user_position_id || form.user_position_id === 1 || $store.state.userData.user_position_id === 2 ||
                        $store.state.userData.user_position_id === 3" color="blue12" depressed :width="330"
                        @click="save" :disabled="!valid" class="rounded-lg font-weight-bold secondary--text"
                        :loading="saveDataLoading">
                        {{ $t('save') }}
                     </v-btn>

                     <v-btn v-if="$store.state.userData.user_position_id === 1 && (form.user_position_id === 2 ||
                        form.user_position_id === 3)" color="blue12" depressed :width="330"
                        @click="target_dialog = true" :disabled="!valid"
                        class="rounded-lg font-weight-bold secondary--text" :loading="saveDataLoading">
                        {{ $t('next') }}
                     </v-btn>
                  </v-col>
               </v-row>
            </div>
            <v-row justify="center" v-if="getDataLoading">
               <v-col cols="auto" class="my-10">
                  <AnimatedLoading :height="143" :hideText="false" :isLoading="getDataLoading" />
               </v-col>
            </v-row>
         </v-form>
         <UserTargetDialog :openDialog="target_dialog" :closeDialog="() => (target_dialog = false)" :UserControlForm="form"
            :rowData="rowData" :backValueMethod="backValueMethod" :userInfo="userInfo" :userDialog="userDialog"
            :position_id="position_id" :closeUserDialog="() => closeDialog()" />
      </v-card>

   </v-dialog>
</template>

<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import UserTargetDialog from '@/views/CRMModule/Users/UserTargetDialog.vue';

export default {
   name: "UserControl",
   data() {
      return {

         valid: false,
         getDataLoading: false,
         isLoading: false,
         loading: false,
         saveDataLoading: false,
         id: null,
         target_dialog: false,
         positionLoading: false,
         position: [],
         userInfo: [],
         position_id: null,
         form: {
            name_ar: null,
            name_en: null,
            user_position_id: null,
            image: null,
            username: null,
            password: null,
         },
      };
   },

   methods: {
      // UPLOAD IMG
      onDrop: function (e) {
         e.stopPropagation();
         e.preventDefault();
         var files = e.dataTransfer.files;
         this.isLoading = true;
         this.createFile(files[0]);
         this.isLoading = false;
      },

      chooseImage() {
         this.$refs.fileInput.click()
      },
      getData() {
         if (!this.position_id && !this.form.name_ar) {
            this.positionLoading = true
            this.$api.GET_METHOD(`users/create`).then((response) => {
               this.positionLoading = false
               if (response.check) {
                  console.log("response", response);
                  this.position = response.data.positions
               }
            })
         }
      },
      employee_image_method() {

         const input = this.$refs.fileInput
         const files = input.files

         if (files && files[0]) {
            const reader = new FileReader
            reader.onload = e => {
               this.form.image = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
         }
      },

      async reset() {
         this.loading = true
         this.isLoading = true;
         this.form.image = null;
         await new Promise(resolve => setTimeout(resolve, 1000))
         this.loading = false
         this.isLoading = false;
      },

      // UPLOAD IMG 

      close() {
         this.form = {
            name_ar: null,
            name_en: null,
            user_position_id: null,
            image: null,
            username: null,
            password: null,
         }
         this.rowData = {}
         this.id = 0
         this.position_id = null;
         this.closeDialog()
      },

      getUserData() {
         this.isLoading = true
         this.$api.GET_METHOD(`user/${this.rowData.id}`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               console.log("response", response);
               this.position_id = response.data.user_position_id
               this.userInfo = response.data.user_info
               this.form = response.data
               this.position = response.data.positions

            }
         })
      },

      save() {
         // Edit  request
         console.log('this.form', this.form);
         this.isLoading = true
         if (Object.keys(this.rowData).length !== 0) {
            this.form.id = this.rowData.id

            if (this.form.image === this.rowData.image) {
               delete this.form.image;
            }

            this.$api.POST_METHOD(`user/update`, this.form).then((response) => {
               this.isLoading = false
               console.log("update response", response);
               if (response.check) {
                  if (this.userDialog) {
                     this.$router.push('/customers');
                  } else {
                     this.backValueMethod(false)
                     this.close();
                  }
               }
            })
         } else {
            // Add  request
            this.isLoading = true
            this.$api.POST_METHOD(`user/store`, this.form).then((response) => {
               this.isLoading = false
               if (response.check) {
                  this.backValueMethod(false)
                  this.close();
               }
            })
         }
      }

   },

   watch: {
      openDialog() {
         if (this.openDialog == true) {
            this.id = +1
            setTimeout(() => this.getData())

         }

      },
      // to handel data  from UserMenu Component
      userDialog() {
         if (this.userDialog) {
            this.form.name_ar = this.rowData.name_ar
            this.form.name_en = this.rowData.name_en
            this.form.user_position_id = this.rowData.type
            this.form.image = this.rowData.image
            this.form.username = this.rowData.username

            this.getUserData()
         }
      },

      rowData() {
         if (Object.keys(this.rowData).length != 0) {
            this.getUserData()
            this.form = { ...this.rowData }

            this.id = null
         }
      }

   },
   computed: {
      dialog_title() {
         if (!this.position_id && this.position_id !== 0) {
            return this.$i18n.t('add a new user')
         } else return `${this.$i18n.t('modify a')} (${this.$vuetify.rtl ? this.form.name_ar : this.form.name_en})`

      }
   },

   mounted() {

   },

   props: {
      openDialog: { default: false },
      userDialog: { default: false },
      rowData: { default: Object },
      closeDialog: { type: Function },
      backValueMethod: { type: Function },

   },
   components: { GenericInput, AnimatedLoading, UserTargetDialog }
}
</script>

<style lang="scss" scoped>
.loading_component {
   padding-top: 10rem !important;
   height: 500px;
}

// Upload IMG STYLE
.image-input {
   display: block;
   height: 110px;
   width: 112px;
   cursor: pointer;
   border-radius: 50%;
   background-size: 100% 100%;
   background-position: center center;
   z-index: 1;
}

.temporary_img_parent {
   z-index: 140;
   position: absolute;
   top: 127px;
   right: 35px;
}


.temporary_img {
   width: 117px;
   max-height: 119px;
   margin-top: 1.1rem;
   margin-right: 12px;
   border-radius: 50%;
   background-color: #fff;
   background-size: 100% 100%;
   background-position: center center;

}

.file-input {
   display: none
}

// Upload IMG STYLE
</style>
