<template>
    <v-dialog v-model="openDialog" persistent max-width="500">
        <v-card relative class=" pa-5">
            <v-form ref="form" v-model="valid">
                <v-row align="center" class="mt-2 rounded-lg">
                    <GenericInput type="float" :value="form.amount" @input="form.amount = $event"
                        label="The amount (in the currency of the client's country)" icon='mdi-cash-multiple'
                        :required="true" :isLoading="false" :cols="[12, 12, 12]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.products" @input="form.products = $event"
                        label="with the product" :lookups="products" selected_label="name" selected_prop="id"
                        :required="true" :multi="true" :isLoading="productsLoading" :cols="[12, 12, 12]">
                    </GenericInput>
                </v-row>

                <v-card-actions class="d-flex justify-space-between align-center mt-2 ">
                    <v-btn color="red12" depressed :width="132" height="36" @click="close"
                        class="font-weight-bold rounded-lg  error--text" :loading="loading">
                        {{ $t('back') }}
                    </v-btn>

                    <v-btn class="font-weight-bold info--text" :width="312" height="36" elevation="0" color="blue12"
                        :disabled="!valid" @click="save" :loading="loading">
                        {{ $t('Closing the deal') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "WinTheDealDialog",
    props: {
        closeDialog: { type: Function },
        saveAction: { type: Function },
        openDialog: { default: Boolean },
        customer_id: { default: Number },
    },
    data: () => ({
        valid: false,
        loading: false,
        productsLoading: false,
        products: [],
        form: {
            client_id: null,
            products: null,
            amount: null,
        }
    }),
    methods: {
        save() {
            this.$route.params.id ? this.form.client_id = +this.$route.params.id : this.form.client_id = +this.customer_id
            if (this.$refs.form.validate()) {
                console.log('form', this.form);
                this.loading = true
                this.$api.POST_METHOD(`client/deal`, this.form).then((response) => {
                    this.loading = false
                    if (response.check) {
                        console.log('response', response);
                        this.close()
                        this.saveAction()
                    }

                })
            }
        },
        close() {
            this.$refs.form.reset();
            this.closeDialog()
        }
    },
    watch: {
        openDialog() {
            if (this.openDialog) {
                this.productsLoading = true
                this.$api.GET_METHOD(`products`).then((response) => {
                    this.productsLoading = false
                    if (response.check) {
                        console.log("products", response.data);
                        this.products = response.data
                    }
                })
            }
        },
    }

}
</script>