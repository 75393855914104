// CRM Module Routes:-

// Customer
import Customers from "../views/CRMModule/Customers/Customers.vue";
import CustomersControl from "../views/CRMModule/Customers/CustomersControl.vue";
import CustomersEntity from "../views/CRMModule/Customers/CustomersEntity.vue";
// Calls And FollowUp
import CallsAndFollowUp from "../views/CRMModule/CallsAndFollowUp/CallsAndFollowUp.vue";
import CallsAndFollowUpEntity from "../views/CRMModule/CallsAndFollowUp/CallsAndFollowUpEntity.vue";
import CallsAndFollowUpControl from "../views/CRMModule/CallsAndFollowUp/CallsAndFollowUpControl.vue";

// Reports
import Reports from "../views/CRMModule/Reports/Reports.vue";
import SalesAgentStatement from "../views/CRMModule/Reports/SalesAgentStatement.vue";
import CustomerStatement from "../views/CRMModule/Reports/CustomerStatement.vue";

// User
import UsersEntity from "../views/CRMModule/Users/UsersEntity.vue";


export const CRMModuleRoutes = [
  // Default page after auth
  {
    path: "/",
    redirect: '/customers',
    meta: {
      screen_code: "01-001",
    },
  },
  // Customer
  {
    path: "/customers",
    name: "customers",
    component: Customers,
    children: [{
      path: "/customers",
      name: "CustomersEntity",
      component: CustomersEntity,
      meta: {
        screen_code: "01-001",
      },
    },
    {
      path: "/customers/customers-control",
      name: "CustomersControl",
      component: CustomersControl,
      meta: {
        screen_code: "01-001",
      },
    },
    {
      path: "/customers/customers-control/:id",
      name: "CustomersControlEdit",
      component: CustomersControl,
      meta: {
        screen_code: "01-001",
      },
    },
    ],
    meta: {
      screen_code: "01-001",
    },
  },

  // Calls And FollowUp
  {

    path: "/calls_followup",
    name: "calls_followup",
    component: CallsAndFollowUp,
    children: [{
      path: "/calls_followup",
      name: "CallsAndFollowUpEntity",
      component: CallsAndFollowUpEntity,
      meta: {
        screen_code: "01-002",
      },
    },
    {
      path: "/calls_followup/calls_followup-control",
      name: "CallsAndFollowUpControl",
      component: CallsAndFollowUpControl,
      meta: {
        screen_code: "01-002",
      },
    },
    {
      path: "/calls_followup/calls_followup-control/:id",
      name: "CallsAndFollowUpControlEdit",
      component: CallsAndFollowUpControl,
      meta: {
        screen_code: "01-002",
      },
    },
    ],
    meta: {
      screen_code: "01-002",
    },

  },
  // Reports 
  {
    path: "/reports",
    redirect: '/reports/sales_agent_statement',
    name: "reports",
    component: Reports,
    children: [

      {
        path: "/reports/sales_agent_statement",
        name: "SalesAgentStatement",
        component: SalesAgentStatement,
        meta: {
          screen_code: "01-003",
        },
      },
      {
        path: "/reports/customer_statement",
        name: "CustomerStatement",
        component: CustomerStatement,
        meta: {
          screen_code: "01-003",
        },
      },


    ]


  },

  // Users
  {
    path: "/users",
    name: "UsersEntity",
    component: UsersEntity,
    meta: {
      screen_code: "01-004",
    },
  },

];