<template>
    <section>
        <v-container fluid>
            <!-- Header -->
            <ControlHeader class="pt-3" :pageData="pageData" :form="form" :save="save" :valid="valid"
                :deleteMethod="deleteMethod" :editForm="pageData.isEdit && !viewForm" :viewForm="() => viewForm = true" />
            <!-- End Of Header -->

            <!-- Form -->
            <v-form ref="form" v-model="valid" class="pa-3" v-if="viewForm">

                <!-- Main Data -->
                <v-row class="align-center light py-2 my-3">
                    <v-col cols="12">
                        <div class="subtitle-1 gray5--text  font-weight-bold">{{ $t('main data') }}</div>
                    </v-col>

                    <GenericInput type="date" :value="form.date" @input="form.date = $event" label="date" :required="true"
                        :isLoading="pageData.editIsLoading" :disabled="pageData.isEdit" :cols="[12, 3, 3]">
                    </GenericInput>

                    <GenericInput type="text" :value="form.name" @input="form.name = $event" label="customer name"
                        :required="true" :isLoading="pageData.editIsLoading" icon="mdi-account-circle-outline"
                        :cols="[12, 3, 3]">
                    </GenericInput>

                    <GenericInput type="number" :value="form.phone" @input="form.phone = $event" label="cell phone number"
                        :required="true" :isLoading="pageData.editIsLoading" icon="mdi-cellphone" :cols="[12, 3, 3]">
                    </GenericInput>

                    <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                        :required="false" :isLoading="pageData.editIsLoading" icon="mdi-email-outline" :cols="[12, 3, 3]">
                    </GenericInput>

                    <GenericInput type="text" :value="form.address" @input="form.address = $event" label="the address"
                        :required="false" :isLoading="pageData.editIsLoading" icon="mdi-home-circle-outline"
                        :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="text" :value="form.google_map" @input="form.google_map = $event"
                        label="address on google map" :required="true" icon="mdi-map-marker"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.responsible_seller_id"
                        @input="form.responsible_seller_id = $event" label="sales representative" :lookups="sellers"
                        selected_label="name" selected_prop="id" :disabled="$store.state.userData.user_position_id !== 1"
                        :required="true" :isLoading="pageData.editIsLoading || pageData.isLoading" :cols="[12, 6, 6]">
                        <template v-slot:append>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </GenericInput>

                    <GenericInput type="select" :value="form.way_found_client_id" @input="form.way_found_client_id = $event"
                        label="the method of finding the customer" :lookups="waysFoundClient" selected_label="name"
                        selected_prop="id" :required="true" :multi="false"
                        :isLoading="pageData.editIsLoading || pageData.isLoading" :cols="[12, 6, 6]">
                    </GenericInput>
                </v-row>

                <!-- The Site -->
                <v-row class="align-center light py-2 my-3">

                    <v-col cols="12">
                        <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('the site') }}</div>
                    </v-col>

                    <GenericInput type="select" :value="form.country_id" @input="form.country_id = $event" label="country"
                        :lookups="handelChangeCountry" selected_label="name" selected_prop="id" :required="true"
                        :isLoading="pageData.editIsLoading || pageData.isLoading" :cols="[12, 4, 4]">
                        <template v-slot:append>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </GenericInput>

                    <v-fade-transition>
                        <GenericInput type="select" v-if="form.country_id" :value="form.city_id"
                            @input="form.city_id = $event" label="the city" :lookups="city" selected_label="name"
                            selected_prop="id" :required="true" :focusin="handelChangeCity" :isLoading="citiesLoading"
                            :cols="[12, 4, 4]">
                            <template v-slot:append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </GenericInput>
                    </v-fade-transition>

                    <v-fade-transition>
                        <GenericInput type="select" v-if="form.city_id" :value="form.area_id" @input="form.area_id = $event"
                            label="region" :lookups="region" selected_label="name" selected_prop="id" :required="false"
                            :focusin="handelChangeRegion" :isLoading="regionLoading" :cols="[12, 4, 4]">
                            <template v-slot:append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </GenericInput>
                    </v-fade-transition>
                </v-row>

                <!-- Company Information -->
                <v-row class="align-center light py-2 my-3">

                    <v-col cols="12">
                        <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('company information') }}</div>
                    </v-col>

                    <GenericInput type="select" :value="form.company_size" @input="form.company_size = $event"
                        label="company size" :lookups="company_size" selected_label="name" selected_prop="id"
                        :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.products_interest" @input="form.products_interest = $event"
                        label="interest in the product" :lookups="products" selected_label="name" selected_prop="id"
                        :required="false" :multi="true" :isLoading="pageData.editIsLoading || pageData.isLoading"
                        :cols="[12, 4, 4]">
                    </GenericInput>

                    <GenericInput type="select" :value="form.company_level" @input="form.company_level = $event"
                        label="company level" :lookups="company_level" selected_label="name" selected_prop="id"
                        :required="false" :isLoading="pageData.editIsLoading" :has_slot="true" :cols="[12, 4, 4]">
                        <template v-slot:selection="item">
                            <div class="d-flex justify-space-between align-center">
                                <span>{{ item.item.name }}</span>
                                <img class="px-2" :src="item.item.image">
                            </div>
                        </template>
                        <template v-slot:item="item">
                            <div class="rating d-flex justify-space-between align-center">
                                <span>{{ item.item.name }}</span>
                                <img :src="item.item.image">
                            </div>
                        </template>

                    </GenericInput>
                </v-row>

                <!-- Note -->
                <v-row class="align-center light py-2 my-3">
                    <v-col cols="12">
                        <div class="subtitle-1 gray5--text font-weight-bold">{{ $t('notes') }}</div>
                    </v-col>

                    <GenericInput type="textarea" :value="form.note" @input="form.note = $event" label="Write your notes"
                        :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                    </GenericInput>
                </v-row>

            </v-form>
            <!-- End of Form -->
        </v-container>
    </section>
</template>
 
 
 
<script>
export default {
    name: "CustomersControl",

    data: () => ({
        pageData: {
            count: 0,
            screen_code: "01-001",
            url: null,
            controlRoute: "customers/customers-control",
            entityName: null,
            main: null,
            category: null,
            isLoading: true,
            isEdit: false,
            editIsLoading: false,
        },
        viewForm: false,
        country: [],
        city: [],
        region: [],
        company_level: [],
        company_size: [],
        products: [],
        sellers: [],
        waysFoundClient: [],
        valid: false,
        citiesLoading: false,
        regionLoading: false,
        handelSellersLoading: false,
        form: {
            date: null,
            name: null,
            phone: null,
            email: null,
            address: null,
            google_map: null,
            responsible_seller_id: null,
            country_id: null,
            city_id: null,
            area_id: null,
            company_size: null,
            products_interest: null,
            company_level: null,
            note: null,
        },
    }),
    components: {
    },
    computed: {
        handelChangeCountry() {
            if (this.form.responsible_seller_id) {
                const sellerObj = this.sellers.find(seller => seller.id == this.form.responsible_seller_id) // get seller object
                return sellerObj.country_id !== undefined && this.country.filter(country => sellerObj.country_id.some(country_id => country_id == country.id))

            } else return this.country
        }
    },
    watch: {
        handelChangeCountry() {
            if (!this.handelChangeCountry) {
                // for reset save validation if sellerObj.country_id = undefined
                this.form.country_id = null;
            }
        },
    },
    mounted() {
        this.form.date = this.$global.GetCurrentDate()
        this.pageMainData()
        this.getData()
        this.company_level = [
            {
                id: 1,
                name: this.$i18n.t('bad'),
                image: require('@/assets/img/svg/Star 1.svg')
            },
            {
                id: 2,
                name: this.$i18n.t('middle'),
                image: require('@/assets/img/svg/Star 2.svg')
            },
            {
                id: 3,
                name: this.$i18n.t('good'),
                image: require('@/assets/img/svg/Star 3.svg')
            },
            {
                id: 4,
                name: this.$i18n.t('very good'),
                image: require('@/assets/img/svg/Star 4.svg')
            },
            {
                id: 5,
                name: this.$i18n.t('amazing'),
                image: require('@/assets/img/svg/Star 5.svg')
            },
        ]
        this.company_size = [
            {
                id: 1,
                name: '0-50',
            },
            {
                id: 2,
                name: '50-100',
            },
            {
                id: 3,
                name: '100-500',
            },
            {
                id: 4,
                name: '500-1000',
            },
            {
                id: 5,
                name: '1000-10000',
            },
        ]
    },
    methods: {
        pageMainData() {
            this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
            this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
            this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
            this.pageData.entityName = this.$global.FilterPermissions(
                this.pageData.screen_code
            ).sub_title;
        },
        getData() {
            if (this.$route.params.id) {
                if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
                    this.pageData.isEdit = true;
                    this.viewForm = true;
                    this.pageData.editIsLoading = true;
                    this.pageData.isLoading = true;
                    this.$api.GET_METHOD(`client/${this.$route.params.id}`).then((response) => {
                        this.pageData.editIsLoading = false;
                        this.pageData.isLoading = false;
                        if (response.check) {
                            console.log("client", response);
                            this.sellers = response.data.sellers
                            this.waysFoundClient = response.data.way_found_clients
                            this.form = response.data.client;
                            this.form.company_level = Number(this.form.company_level)
                            this.form.products_interest = []
                            response.data.client.products.map(e => this.form.products_interest.push(e.id))
                            this.products = response.data.products
                            this.country = response.data.countries

                            // city
                            this.city = [response.data.client.city]
                            this.form.city_id = response.data.client.city.id

                            // region
                            this.region = [response.data.client.area]
                            this.form.area_id = response.data.client.area.id
                        } else {
                            this.$router.push('/')
                        }
                    })
                } else {
                    this.$router.push('/')
                }
            }
            else {
                if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
                    this.pageData.isLoading = true;
                    this.viewForm = true;
                    this.$api.GET_METHOD(`clients/create`).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.country = response.data.countries
                            this.products = response.data.products
                            this.sellers = response.data.sellers
                            this.waysFoundClient = response.data.way_found_clients
                            this.$store.state.userData.user_position_id !== 1 && (this.form.responsible_seller_id = this.$store.state.userData.id)
                        }
                    })
                } else {
                    this.$router.push('/')
                }
            }

        },
        handelChangeCity() {
            this.citiesLoading = true
            this.$api.GET_METHOD(`cities/${this.form.country_id}`).then((response) => {
                this.citiesLoading = false
                if (response.check) {
                    this.city = response.data
                    this.region = null
                }
            })
        },
        handelChangeRegion() {
            this.regionLoading = true
            this.$api.GET_METHOD(`areas/${this.form.city_id}`).then((response) => {
                this.regionLoading = false
                if (response.check) {
                    this.region = response.data.areas
                }
            })
        },
        save() {
            if (this.$refs.form.validate()) {
                this.pageData.isLoading = true;
                if (this.pageData.isEdit == true) {
                    this.$api.POST_METHOD(`client/update`, this.form).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.$router.push(this.pageData.url)
                        }
                    })
                } else {
                    this.$api.POST_METHOD(`client/store`, this.form).then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.$router.push(this.pageData.url)
                        }
                    })
                }
            }
        },
        deleteMethod(status) {
            if (status == true) {
                this.pageData.isLoading = true
                this.$api.POST_METHOD(`client/toggleActive/${this.form.id}`).then(() => {
                    this.$router.push(this.pageData.url);
                    this.pageData.isLoading = false
                })
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.rating {
    width: 100%;
}
</style>
 