import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";
let row_data = null;
let row_index = null;
let data_array = null;
export default {
  install(Vue) {
    Vue.prototype.$global = {
      requiredRule: [(v) => !!v || i18n.t("required")],
      notRequiredRule: [],
      requiredNumberRule: [
        (v) =>
          Number(v.replace(/[^0-9.-]+/g, "")) > 0 ||
          i18n.t("please enter valid number"),
      ],
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.t("Please enter email address"),
      ],
      requiredNumberAcceptZeroRule: [
        (v) => v.toString().length >= 1 || i18n.t("please enter valid number"),
      ],
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      usernameRule: [
        (v) => !!(v || "").match(/^[a-z][0-9a-z_]{2,23}[0-9a-z]$/) || i18n.t("username not valid")
      ],
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],
      percentageRule: [
        (v) =>
          (v.toString().length >= 1 && v <= 100) ||
          i18n.t("percentage not valid"),
      ],
      filterData: {},
      /////////  Array /////////
      StoreFilter(data, key) {
        console.log('data', typeof data == 'undefined', this.filterData);
        if (typeof data == 'undefined') {
          return this.filterData
        } else {
          return this.filterData[key] = data
        }
      },
      CallBackMethod(data, index, arr) {
        // 1- return data in function if pass data and index 
        if (typeof index === "number" && data) {
          row_data = { ...data }
          row_index = index
          data_array = arr
          return data
        }
        // 2- no augment pass in function
        else if (!data && !index) {
          return row_data
        }
        //3- update array  without response
        else {
          data_array[row_index] = { ...data };
          row_data = null;
          row_index = null;
          data_array = null;
        }
      },
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy, ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter[0][ReturnElement];
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      // Dates
      GetCurrentDate() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        return [year, month, day].join('-');
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice((PAGE_NUMBER - 1) * PAGE_SIZE, PAGE_NUMBER * PAGE_SIZE);
      },
      GetFirstDayOfMonth() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split('T')[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        const dateOne = new Date(FIRST_DATE);
        const dateTwo = new Date(SECOND_DATE);
        return dateTwo.getDate() - dateOne.getDate();
      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },

      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },

      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      FILE_TYPE(FILE) {
        // Base64
        console.log(FILE);
        if (String(FILE).substring(0, 5) == 'data:') {
          console.log('FILE TYPE =>', FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]);
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              return 'excel';
            case 'application/vnd.ms-excel':
              return 'excel';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'word';
            case 'application/octet-stream':
              return 'rar';
            case 'application/x-zip-compressed':
              return 'zip';
            case 'application/json':
              return 'json';
            case 'application/pdf':
              return 'pdf';
            case 'image/png':
              return 'png';
            case 'image/jpg':
              return 'jpg';
            case 'image/jpeg':
              return 'jpeg';
            case 'text/plain':
              return 'txt';
            case 'image/svg+xml':
              return 'svg';
            case 'image/gif':
              return 'gif';
            default:
              // return FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              return 'notFound';
          }
        }
        // Normal
        else {
          // return FILE ? FILE.split('.').pop() : '';
          switch (FILE.type) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              return 'excel';
            case 'application/vnd.ms-excel':
              return 'excel';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'word';
            case 'application/octet-stream':
              return 'rar';
            case 'application/x-zip-compressed':
              return 'zip';
            case 'application/json':
              return 'json';
            case 'application/pdf':
              return 'pdf';
            case 'image/png':
              return 'png';
            case 'image/jpg':
              return 'jpg';
            case 'image/jpeg':
              return 'jpeg';
            case 'text/plain':
              return 'txt';
            case 'image/svg+xml':
              return 'svg';
            case 'image/gif':
              return 'gif';
            default:
              // return FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              return 'notFound';
          }
        }

      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },
    };
  },
};
