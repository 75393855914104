<template>
    <section>
        <v-container fluid>
            <section class=" mx-2">
                <!-- search input -->
                <v-row class="pt-1 mb-3 statement">
                    <v-col cols="11">
                        <GenericInput type="select" :lookups="clientsArray" :value="form.client_id"
                            @input="form.client_id = $event" label="search by customer name" :required="false"
                            :cols="[12, 12, 12]" selected_label="name" selected_prop="id" :isLoading="isLoading">
                            <template v-slot:append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </GenericInput>

                    </v-col>
                    <v-col cols="1">
                        <v-btn @click="getData" :loading="isLoading" depressed :width="90" color="blue11"
                            :disabled="!form.client_id" class="primary--text font-weight-bold mt-1 search">
                            {{ $t('search') | capitalize }}
                        </v-btn>
                    </v-col>
                </v-row>

            </section>

            <!-- ANIMATION loading -->
            <AnimatedLoading classes="white ma-auto loading_component_All" :hideText="false" :isLoading="isLoading" />
            <!-- details -->
            <section class="details  mx-n1" v-if="!isLoading">
                <v-row class="pa-5 mt-4" v-if="Object.keys(client).at(1)">
                    <v-col cols="12" class="d-flex justify-space-between">
                        <span class="potential_client px-3 py-1">{{ $t('potential client') }}</span>
                        <span class="number_Of_calls d-flex flex-column px-3">
                            <h6 class="mt-2">{{ $t('number of calls') }}</h6>
                            <span>{{ client.calls_count }}</span>
                        </span>
                    </v-col>
                    <!-- customer name -->
                    <v-col cols="4">
                        <div class="mb-3">
                            <span class="caption">{{ $t('customer name') | capitalize }}</span>
                            <h4>{{ client.name }}</h4>

                        </div>
                        <div>
                            <span class="caption">{{ $t('the address') | capitalize }}</span>
                            <p>{{ client.address }}</p>

                        </div>
                        <div>
                            <span class="caption">{{ $t('country') | capitalize }}</span>
                            <p v-if="client.country">{{ client.country.name }}</p>

                        </div>
                        <div>
                            <span class="caption">{{ $t('company size') | capitalize }}</span>
                            <p v-if="client.company_size != null">
                                {{ $global.FilterArrayOfObjectElement(company_size, 'id', client.company_size, 'name') }}
                            </p>

                        </div>
                    </v-col>
                    <!-- cell phone number -->
                    <v-col cols="4">
                        <div>
                            <span class="caption">{{ $t('cell phone number') | capitalize }}</span>
                            <h4>{{ client.phone }}</h4>

                        </div>

                        <div class="city">
                            <span class="caption">{{ $t('the city') | capitalize }}</span>
                            <p>{{ client.city.name }}</p>

                        </div>
                        <div>
                            <span class="caption">{{ $t('company level') | capitalize }}</span>
                            <p>{{ client.company_level }}</p>

                        </div>
                    </v-col>
                    <!-- E-mail -->
                    <v-col cols="4">
                        <div class="mb-4 d-flex justify-space-between">
                            <span>
                                <span class="caption">{{ $t('E-mail') | capitalize }}</span>
                                <h4>{{ client.email }}</h4>
                            </span>
                            <img @click="edit(client.id)" class="mt-3 cursor_pointer"
                                v-if="$store.state.userData.user_position_id === 1"
                                src="@/assets/img/svg/report_edit_icon.svg" alt="edit">

                        </div>
                        <div>

                            <span class="caption d-block">{{ $t('address on google map') | capitalize }}</span>
                            <a :href="client.google_map" target="_blank">{{ client.google_map }}</a>
                        </div>
                        <div class="mt-4">
                            <span class="caption">{{ $t('region') | capitalize }}</span>
                            <p>{{ client.area.name }}</p>
                        </div>
                        <div>
                            <span class="caption">{{ $t('interest in the product') | capitalize }}</span>
                            <p v-for="product in client.products" :key="product.id">
                                <span>{{ product.name }}</span>
                            </p>
                        </div>
                    </v-col>
                </v-row>

            </section>
            <!-- search document no data img-->
            <div class="search-document" v-if="Object.keys(client).length == 0 && !isLoading">
                <img src="@/assets/img/svg/search-document.svg" alt="search-document">
                <h5 class="description mt-3">{{ $t('search for the report') }}</h5>
            </div>
        </v-container>
    </section>
</template>
  
<script>

export default {
    name: "CustomerStatement",

    data: () => ({
        pageData: {
            screen_code: "01-003",
            url: null,
            controlRoute: "/reports/customer-statement",
            entityName: null,
            main: null,
            category: null,
            isLoading: false,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        tab: 0,
        isLoading: false,
        client: {},
        clientsArray: [],
        company_size: [],
        form: {
            client_id: null
        }
    }),
    components: {},
    computed: {},
    mounted() {
        this.clientArray()
        this.company_size = [
            {
                id: 1,
                name: '0-50',
            },
            {
                id: 2,
                name: '50-100',
            },
            {
                id: 3,
                name: '100-500',
            },
            {
                id: 4,
                name: '500-1000',
            },
            {
                id: 5,
                name: '1000-10000',
            },
        ]
    },
    watch: {
        'form.client_id'() {
            if (this.form.client_id) {
                this.getData()
            }
        }
    },
    methods: {

        getData(applayLoading = true) {
            this.isLoading = true
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                if (applayLoading == true) {
                    this.pageData.isLoading = true;
                }
                this.$api
                    .GET_METHOD(`reports/client?client_id=${this.form.client_id}`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        this.isLoading = false

                        if (response.check) {
                            this.client = response.data.client;
                            this.pageData.entityName = this.$i18n.t("users")
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;


                        }
                    });
            } else {
                this.$router.push("/");
            }
        },

        clientArray(applayLoading = true) {
            this.isLoading = true
            if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
                if (applayLoading == true) {
                    this.pageData.isLoading = true;
                }

                this.$api
                    .GET_METHOD(`clients/all`)
                    .then((response) => {
                        this.pageData.isLoading = false;
                        if (response.check) {
                            this.isLoading = false
                            this.pageData.entityName = this.$i18n.t("users")
                            this.pageData.isTrashed == 0
                                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                                : this.$store.state.activeScreen.sub_title;
                            this.clientsArray = response.data.clients;


                        }
                    });
            } else {
                this.$router.push("/");
            }
        },

        edit(id) {
            this.$router.push(`/customers/customers-control/${id}`);
        },

    },
};
</script>
  
<style lang="scss" scoped>
.loading_component_All {
    padding-top: 2rem !important;
    /* height: 70vh !important */
}

.v-tab {
    font-weight: 400;
    font-size: 14px;
    color: #8E8C94;
    width: 241px;
    font-weight: 500 !important;

}


/* .theme--light.v-tabs>.v-tabs-bar .v-tab {
    font-weight: 500 !important;
    width: 241px;
} */

.v-tab.v-tab--active {
    background: #E6ECFF;
    border-bottom: 5px solid #4D77FF;
    border-radius: 6px 6px 0px 0px;
    padding: 16px 55px;
    top: 3px;

}

.details {
    background-color: #F9F9F9;

}

.potential_client {
    background: #FFE7D9;
    border-radius: 35.8387px;
    color: #FF7F37;
    font-weight: 700;
    font-size: 19.5484px;
    line-height: 2;

}

.number_Of_calls {
    background: #E6ECFF;
    border-radius: 8px;
    text-align: center;

}

.city {
    margin-top: 70px;
}

.statement {
    background: #F9F9F9;
    border-radius: 6px;
    padding: 0 5px;
}

.search-document {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.search-document .description {
    font-weight: 700;
    font-size: 21.1261px;

    color: #8E8C94;
}
</style>